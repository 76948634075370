import React, { HTMLAttributes, useRef, useEffect } from 'react';
import repository from 'dy-frontend-http-repository/lib/repository';
import { AvailableCouponResource } from 'dy-frontend-http-repository/lib/modules/Coupon/resources';
import { useForm, useTextFormField } from '@app/hooks';
import { getStringRequiredValidator, getStringMaxLengthValidator } from '@app/hooks/validation/functions';
import InputFormField from '@components/InputFormField';
import Flex from '@components/Flex';
import { Button } from '@blueprintjs/core';

export interface ApplySubscriptionCouponFormProps {
    onSuccess: (coupon: AvailableCouponResource) => void;
}
export type Props = Omit<HTMLAttributes<HTMLFormElement>, 'onSubmit'> & ApplySubscriptionCouponFormProps;

const subscriptionCouponValidators = [
    getStringRequiredValidator(),
    getStringMaxLengthValidator({ maxStringLength: 255 }),
];

const ApplySubscriptionCouponForm: React.FC<Props> = ({ onSuccess, ...props }) => {
    const couponInputRef = useRef<HTMLInputElement | null>(null);

    const subscriptionCoupon = useTextFormField({
        id: 'subscription-coupon',
        initialValue: '',
        validators: subscriptionCouponValidators,
    });

    const form = useForm<AvailableCouponResource>({
        fields: [subscriptionCoupon],
        apiCall: async () => {
            try {
                const coupon = await repository.coupon().getAvailable({ query: subscriptionCoupon.value });
                return { response: coupon };
            } catch (e) {
                throw e;
            }
        },
        onSuccess: ({ response: coupon }) => {
            onSuccess(coupon);
        },
        onFailure: (e) => {
            // TODO: handle error
            console.error(e);
        },
    });

    useEffect(() => {
        if (couponInputRef.current === null) {
            return;
        }

        couponInputRef.current.focus();
    }, [couponInputRef]);

    return (
        <form onSubmit={form.handleFormSubmit} {...props}>
            <InputFormField
                field={subscriptionCoupon}
                formGroupProps={{ label: 'Coupon' }}
                inputProps={{ leftIcon: 'tag', placeholder: 'Enter coupon', inputRef: couponInputRef }}
            />

            <Flex className="mt-2" justify="flex-end">
                <Button disabled={form.hasFieldErrors} loading={form.isSubmitting} type="submit">
                    Apply Coupon
                </Button>
            </Flex>
        </form>
    );
};

export default ApplySubscriptionCouponForm;
