import UpdateSubscriptionItemsModal, {
    UpdateSubscriptionItemsModalProps,
} from '@app/containers/modals/UpdateSubscriptionItemsModal';
import UpdateSubscriptionSingleItemModal, {
    UpdateSubscriptionSingleItemModalProps,
} from '@app/containers/modals/UpdateSubscriptionSingleItemModal';
import { $permissions } from '@app/containers/store/states';
import { Button, Card, Colors, Icon, IconName, Tooltip } from '@blueprintjs/core';
import Circle from '@components/Circle';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import {
    checkIfSwitchMultipleItemsActionAllowed,
    checkIfSwitchSinglePriceActionAllowed,
} from '@data/functions/subscription';
import { openModal } from '@modals/store/events';
import {
    PriceResource,
    SubscriptionItemResource,
    SubscriptionResource,
} from 'dy-frontend-http-repository/lib/modules/Subscription/resources';
import { PriceUtils } from 'dy-frontend-shared/lib/utils';
import { useStore } from 'effector-react';
import React, { HTMLAttributes } from 'react';
import { fetchSubscription } from '../../store/effects';
import { SubscriptionPermission } from 'dy-frontend-permissions/lib/permission';
import CreatePlanToPriceAttachmentModal, {
    CreatePlanToPriceAttachmentModalProps,
} from '../../modals/CreatePlanToPriceAttachmentModal';
import { PlanType } from 'dy-frontend-http-repository/lib/data/enums';

export interface SubscriptionItemsProps {
    subscription: SubscriptionResource;
}

export type Props = HTMLAttributes<HTMLDivElement> & SubscriptionItemsProps;

const SubscriptionItems: React.FC<Props> = ({ subscription, ...props }) => {
    const permissions = useStore($permissions);

    if (subscription.items.length === 0) {
        // Subscription have no items
        return null;
    }

    const getSubscriptionItemFullPrice = (subscriptionItem: SubscriptionItemResource, price: PriceResource) => {
        // Get subscription items full price with quantity
        const totalSubscriptionItemPriceWithQuantity = PriceUtils.formatPrice({
            price: price.unit_amount * subscriptionItem.quantity,
            shouldDisplayCents: true,
        });

        return totalSubscriptionItemPriceWithQuantity;
    };

    const getSubscriptionItemPriceCurrency = (price: PriceResource) => {
        // Get currency
        return price.currency.toUpperCase();
    };

    const getSubscriptionItemPrice = (price: PriceResource) => {
        // Get price for single subscription item
        const subscriptionItemPriceFormatted = PriceUtils.formatPrice({
            price: price.unit_amount,
            shouldDisplayCents: true,
        });

        return subscriptionItemPriceFormatted;
    };

    const getSubscriptionItemPriceRecurringInterval = (price: PriceResource) => {
        // Get label for recurring interval "2 days", "day", "3 months", etc.
        const subscriptionItemRecurringInterval = PriceUtils.getRecurringInterval(
            price.recurring_interval,
            price.recurring_interval_count,
        );

        return subscriptionItemRecurringInterval;
    };

    const renderSubscriptionItemHeader = ({
        icon,
        title,
        titleColor,
        quantity,
    }: {
        icon: IconName;
        title: string;
        titleColor: string;
        quantity: number;
    }) => {
        return (
            <Flex direction="row" align="center">
                <Icon icon={icon} className="mr-1" color={titleColor} />
                <Heading type="h5">{title}</Heading>
                <Circle className="ml-1 mr-1" size="6px" color={Colors.GRAY2} />
                <DevText muted>x{quantity}</DevText>
            </Flex>
        );
    };

    const renderUpdateSingleSubscriptionItemButton = (price: PriceResource, subscriptionItemQuantity: number) => {
        const isAllowed = permissions.isRoot.subscription || permissions.has(SubscriptionPermission.ITEM_UPDATE_SINGLE);
        if (!isAllowed) {
            return null;
        }

        if (!price.plan) {
            // Price is NOT part of plan
            return null;
        }

        const isUpdateSingleSubscriptionItemPriceAllowed = checkIfSwitchSinglePriceActionAllowed(
            price,
            subscription,
            price.plan.type,
        );

        if (!isUpdateSingleSubscriptionItemPriceAllowed) {
            // Update single subscription items price NOT allowed
            return null;
        }

        return (
            <Tooltip content="Switch plan or price">
                <Button
                    className="ml-1"
                    minimal
                    icon="undo"
                    onClick={() =>
                        openModal<UpdateSubscriptionSingleItemModalProps>({
                            ModalComponent: UpdateSubscriptionSingleItemModal,
                            data: {
                                price,
                                subscriptionId: subscription.id,
                                subscriptionMode: subscription.mode,
                                subscriptionItemQuantity: subscriptionItemQuantity,
                                selectedPriceIds: subscription.items
                                    .map((item) => (item.price ? item.price.id : null))
                                    .filter((priceId) => priceId !== null),
                                refetchOnSuccess: () => fetchSubscription(subscription.id),
                            },
                        })
                    }
                />
            </Tooltip>
        );
    };

    const renderCreatePriceToPlanAttachmentButton = (price: PriceResource, priceExternalId: string) => {
        // TODO: if workspace finished, need somehow understand where to take plan type, may be do modal with 2 steps where at the first step user select plan type

        return (
            <Button
                icon="one-to-one"
                className="ml-2"
                onClick={() =>
                    openModal<CreatePlanToPriceAttachmentModalProps>({
                        ModalComponent: CreatePlanToPriceAttachmentModal,
                        data: {
                            subscriptionId: subscription.external_id,
                            planType: PlanType.USER,
                            price,
                            priceExternalId,
                            refetchOnSuccess: () => fetchSubscription(subscription.id),
                        },
                    })
                }
            >
                Attach to plan
            </Button>
        );
    };

    const renderSubscriptionItemWithoutPrice = (subscriptionItem: SubscriptionItemResource) => {
        return (
            <Card className="mb-2">
                <Flex direction="row" justify="space-between">
                    <div>
                        {renderSubscriptionItemHeader({
                            icon: 'help',
                            title: 'Unknown additional payment',
                            quantity: subscriptionItem.quantity,
                            titleColor: Colors.GRAY2,
                        })}

                        <Flex direction="row" align="center">
                            <Icon className="mr-1" icon="warning-sign" color={Colors.ORANGE4} />
                            <DevText muted>Price details are unknown. Sync price data to get info</DevText>
                        </Flex>
                    </div>

                    <DevText muted>Total: Unknown</DevText>
                </Flex>
            </Card>
        );
    };

    const renderSubscriptionItemPlanPrice = (subscriptionItem: SubscriptionItemResource, price: PriceResource) => {
        if (!price.plan) {
            return null;
        }

        const subscriptionItemFullPrice = getSubscriptionItemFullPrice(subscriptionItem, price);
        const subscriptionItemPrice = getSubscriptionItemPrice(price);
        const subscriptionItemPriceCurrency = getSubscriptionItemPriceCurrency(price);
        const subscriptionItemPriceRecurringInterval = getSubscriptionItemPriceRecurringInterval(price);

        // Price have relation to plan
        return (
            <Card className="mb-2">
                <Flex direction="row" justify="space-between">
                    <div>
                        <div className="mb-1">
                            {renderSubscriptionItemHeader({
                                icon: 'box',
                                title: price.plan.title,
                                quantity: subscriptionItem.quantity,
                                titleColor: price.plan.color,
                            })}
                        </div>

                        <DevText muted>
                            {subscriptionItemPrice} {subscriptionItemPriceCurrency} /{' '}
                            {subscriptionItemPriceRecurringInterval}
                        </DevText>
                    </div>

                    <Flex direction="row" align="center">
                        <DevText muted>Total: {subscriptionItemFullPrice}</DevText>

                        {renderUpdateSingleSubscriptionItemButton(price, subscriptionItem.quantity)}
                    </Flex>
                </Flex>
            </Card>
        );
    };

    const renderSubscriptionItemChargePrice = (subscriptionItem: SubscriptionItemResource, price: PriceResource) => {
        const subscriptionItemFullPrice = getSubscriptionItemFullPrice(subscriptionItem, price);
        const subscriptionItemPrice = getSubscriptionItemPrice(price);
        const subscriptionItemPriceCurrency = getSubscriptionItemPriceCurrency(price);
        const subscriptionItemPriceRecurringInterval = getSubscriptionItemPriceRecurringInterval(price);

        return (
            <Card className="mb-2">
                <Flex direction="row" justify="space-between">
                    <div>
                        {renderSubscriptionItemHeader({
                            icon: 'bank-account',
                            title: `Additional payment: ${subscriptionItemPrice} ${subscriptionItemPriceCurrency}`,
                            quantity: subscriptionItem.quantity,
                            titleColor: Colors.WHITE,
                        })}

                        <DevText muted>
                            {subscriptionItemPrice} {subscriptionItemPriceCurrency} /{' '}
                            {subscriptionItemPriceRecurringInterval}
                        </DevText>
                    </div>

                    <Flex direction="row" align="center">
                        <DevText muted>Total: {subscriptionItemFullPrice}</DevText>
                        {renderCreatePriceToPlanAttachmentButton(price, subscriptionItem.price_external_id)}
                    </Flex>
                </Flex>
            </Card>
        );
    };

    const renderUpdateSubscriptionItemsButton = () => {
        const isAllowed = permissions.isRoot.subscription || permissions.has(SubscriptionPermission.ITEM_UPDATE_BATCH);
        if (!isAllowed) {
            return null;
        }

        const subscriptionItemWithPlan = subscription.items.find(
            (item) => item.price !== null && item.price.plan !== null && item,
        );
        if (!subscriptionItemWithPlan) {
            return null;
        }

        const subscriptionItemsPrices = subscription.items.map((item) => item.price);
        const isUpdateSubscriptionItemsActionAllowed = checkIfSwitchMultipleItemsActionAllowed(
            subscriptionItemsPrices,
            subscription,
            subscriptionItemWithPlan.price!.plan!.type,
        );

        if (!isUpdateSubscriptionItemsActionAllowed) {
            // Update subscription items is NOT allowed
            return null;
        }

        const handleClickUpdateItems = () => {
            openModal<UpdateSubscriptionItemsModalProps>({
                ModalComponent: UpdateSubscriptionItemsModal,
                data: {
                    subscription,
                    refetchOnSuccess: () => fetchSubscription(subscription.id),
                },
            });
        };

        return <Button onClick={handleClickUpdateItems}>Update items</Button>;
    };

    const renderSubscriptionItem = (subscriptionItem: SubscriptionItemResource) => {
        const price = subscriptionItem.price;

        if (!price) {
            // Unknown item (no price)
            return renderSubscriptionItemWithoutPrice(subscriptionItem);
        } else {
            if (price.plan) {
                // Price is part of plan
                return renderSubscriptionItemPlanPrice(subscriptionItem, price);
            } else {
                // Price is NOT a part of plan, regular charge
                return renderSubscriptionItemChargePrice(subscriptionItem, price);
            }
        }
    };

    return (
        <div {...props}>
            <Flex className="mb-1" direction="row" align="center" justify="space-between">
                <Heading type="h4">Subscription Items</Heading>
                {renderUpdateSubscriptionItemsButton()}
            </Flex>

            {subscription.items.map(renderSubscriptionItem)}
        </div>
    );
};

export default SubscriptionItems;
