import Pagination from '@app/components/Pagination';
import CreateSubscriptionModal, { CreateSubscriptionModalProps } from '@app/containers/modals/CreateSubscriptionModal';
import { $permissions } from '@app/containers/store/states';
import { Button, Card, Colors, Divider, Icon, Intent, Spinner } from '@blueprintjs/core';
import Circle from '@components/Circle';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import ListCollapse from '@components/ListCollapse';
import NonIdealState from '@components/NonIdealState';
import DevText from '@components/Text';
import SubscriptionStateTags from '@containers/components/SubscriptionStateTags';
import { Endpoints } from '@data/consts';
import { openModal } from '@modals/store/events';
import { $client, $clientPaymentAccount } from '@pages/Client/store/states';
import { UserType } from 'dy-frontend-http-repository/lib/data/enums';
import { SubscriptionMode } from 'dy-frontend-http-repository/lib/modules/Subscription/enums';
import {
    SubscriptionItemResource,
    SubscriptionResource,
} from 'dy-frontend-http-repository/lib/modules/Subscription/resources';
import { CouponUtils, PriceUtils } from 'dy-frontend-shared/lib/utils';
import { useStore } from 'effector-react';
import moment from 'moment';
import React, { HTMLAttributes, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { amountOfClientInvoicesOnPage, amountOfSubscriptionsOnPage } from '../../consts';
import { fetchSubscriptions } from '../../store/effects';
import { $subscriptions } from '../../store/states';
import { SubscriptionPermission } from 'dy-frontend-permissions/lib/permission';

export type Props = HTMLAttributes<HTMLDivElement>;

const Subscriptions: React.FC<Props> = (props) => {
    const permissions = useStore($permissions);
    const navigate = useNavigate();

    const client = useStore($client);
    const clientSubscriptions = useStore($subscriptions);
    const clientPaymentAccount = useStore($clientPaymentAccount);
    const isFetchingClientSubscriptions = useStore(fetchSubscriptions.pending);

    const [isSubscriptionsCollapsed, setIsSubscriptionsCollapsed] = useState(true);
    const [page, setPage] = useState(1);

    const isSubscriptionRootPermission = permissions.has(SubscriptionPermission.ROOT);

    const handleLoadClientSubscriptionsPage = async (newPage: number) => {
        if (!clientPaymentAccount) return;

        const pageOffset = (newPage - 1) * amountOfClientInvoicesOnPage;

        try {
            await fetchSubscriptions({
                pagination: {
                    _pagination: { limit: amountOfSubscriptionsOnPage, offset: pageOffset },
                },
                filter: { payment_account_id: clientPaymentAccount.id },
            });
        } catch (e) {
            // TODO: handle error
            console.error(e);
        }
    };

    useEffect(() => {
        handleLoadClientSubscriptionsPage(page);

        // eslint-disable-next-line
    }, [page]);

    if (!client || !clientPaymentAccount) {
        return null;
    }

    const renderSubscriptionHeader = (subscription: SubscriptionResource) => {
        return (
            <Flex align="center" justify="space-between">
                <Flex direction="row" align="center">
                    <SubscriptionStateTags subscription={subscription} />
                    <Heading type="h5">{moment(subscription.current_period.start_at).format('D MMM, YYYY')}</Heading>
                    <Icon className="ml-1 mr-1" icon="chevron-right" color={Colors.GRAY2} />
                    <Heading type="h5">{moment(subscription.current_period.end_at).format('D MMM, YYYY')}</Heading>
                </Flex>

                <Button onClick={() => navigate(Endpoints.SUBSCRIPTION.replace(':subscriptionId', subscription.id))}>
                    Go to Subscription page
                </Button>
            </Flex>
        );
    };

    const renderSubscriptionItem = (subscriptionItem: SubscriptionItemResource) => {
        const renderUnknownSubscriptionItem = () => {
            return (
                <Flex className="mt-1" direction="row" align="center">
                    <Icon icon="help" className="mr-1" color={Colors.GRAY2} />
                    <Heading muted type="h5" className="mb-1">
                        Unknown additional payment
                    </Heading>
                    <DevText muted>x{subscriptionItem.quantity}</DevText>
                    <Circle className="ml-2 mr-2" size="6px" color={Colors.GRAY2} />
                    <Icon className="mr-1" icon="warning-sign" color={Colors.ORANGE4} />
                    <DevText muted>Sync price to fetch info</DevText>
                </Flex>
            );
        };

        const price = subscriptionItem.price;

        if (!price) {
            return renderUnknownSubscriptionItem();
        }

        // Get currency
        const subscriptionItemPriceCurrency = price.currency.toUpperCase();

        // Get price for single subscription item
        const subscriptionItemPriceFormatted = PriceUtils.formatPrice({
            price: price.unit_amount,
            shouldDisplayCents: true,
        });

        // Get label for recurring interval "2 days", "day", "3 months", etc.
        const subscriptionItemRecurringInterval = PriceUtils.getRecurringInterval(
            price.recurring_interval,
            price.recurring_interval_count,
        );

        const renderSubscriptionItemWithPlan = () => {
            const plan = price.plan!;

            return (
                <Flex className="mt-1" direction="row" align="center">
                    <Icon icon="box" className="mr-1" color={plan.color} />
                    <Heading type="h5" className="mr-1">
                        {plan.title}
                    </Heading>
                    <DevText muted>x{subscriptionItem.quantity}</DevText>
                    <Circle className="ml-2 mr-2" size="6px" color={Colors.GRAY2} />
                    <DevText muted>
                        {subscriptionItemPriceFormatted} {subscriptionItemPriceCurrency} /{' '}
                        {subscriptionItemRecurringInterval}
                    </DevText>
                </Flex>
            );
        };

        const renderSubscriptionItemWithoutPlan = () => {
            return (
                <Flex className="mt-1" direction="row" align="center">
                    <Icon icon="bank-account" className="mr-1" />
                    <Heading type="h5" className="mr-1">
                        Additional payment: {subscriptionItemPriceFormatted}
                    </Heading>
                    <DevText muted>x{subscriptionItem.quantity}</DevText>
                    <Circle className="ml-2 mr-2" size="6px" color={Colors.GRAY2} />
                    <DevText muted>
                        {subscriptionItemPriceFormatted} {subscriptionItemPriceCurrency} /{' '}
                        {subscriptionItemRecurringInterval}
                    </DevText>
                </Flex>
            );
        };

        if (price.plan) {
            // Plan related to price
            return renderSubscriptionItemWithPlan();
        } else {
            // Plan is NOT related to price, meaning it's regular payment item
            return renderSubscriptionItemWithoutPlan();
        }
    };

    const renderSubscriptionItems = (subscription: SubscriptionResource) => {
        if (subscription.items.length === 0) {
            // Subscription do NOT have any subscription items
            return null;
        }

        return (
            <>
                <Divider className="mt-1 mb-1" />
                <div>
                    <DevText muted className="mb-small">
                        Subscription items
                    </DevText>

                    {subscription.items.map(renderSubscriptionItem)}
                </div>
            </>
        );
    };

    const renderSubscriptionCoupon = (subscription: SubscriptionResource) => {
        const coupon = subscription.discount.coupon;

        if (!coupon) {
            // Subscription do NOT have coupon attached
            return null;
        }

        // Get duration text
        const durationText = CouponUtils.getCouponDurationText(coupon);

        // Get price/percentage discount text
        const couponDiscountText = CouponUtils.getCouponDiscountText(coupon);

        return (
            <div className="mt-2">
                <DevText muted className="mb-small">
                    Coupon
                </DevText>

                <DevText>
                    {couponDiscountText} / {coupon.title} / {durationText}
                </DevText>
            </div>
        );
    };

    const renderSubscriptionPaymentMethod = (subscription: SubscriptionResource) => {
        const renderSubscriptionPaymentMethodLabel = () => {
            const paymentMethod = subscription.payment_method;

            if (!paymentMethod) {
                return <DevText>Default or current payment method used</DevText>;
            }

            return (
                <Flex direction="row" align="center">
                    <Icon className="mr-1" icon="credit-card" size={25} />
                    <DevText>
                        {paymentMethod.card_brand.toUpperCase()} ending in *{paymentMethod.card_last_four}
                    </DevText>
                    <DevText muted className="ml-1">
                        {`${paymentMethod.card_expiration_month}`.padStart(2, '0')}/{paymentMethod.card_expiration_year}
                    </DevText>
                </Flex>
            );
        };

        return (
            <div className="mt-2">
                <DevText muted className="mb-small">
                    Payment Method
                </DevText>

                {renderSubscriptionPaymentMethodLabel()}
            </div>
        );
    };

    const renderSubscriptionTrial = (subscription: SubscriptionResource) => {
        if (!subscription.trial.end_at) {
            // Subscription do NOT have trial end date set
            return null;
        }

        return (
            <div className="mt-2">
                <DevText muted className="mb-small">
                    Trial
                </DevText>

                <DevText>Set trial end {moment(subscription.trial.end_at).fromNow()}</DevText>
            </div>
        );
    };

    const renderCreateSubscriptionButton = () => {
        const isAllowed = isSubscriptionRootPermission || permissions.has(SubscriptionPermission.CREATE);
        if (!isAllowed) {
            return null;
        }

        return (
            <Button
                intent={Intent.PRIMARY}
                onClick={() =>
                    openModal<CreateSubscriptionModalProps>({
                        ModalComponent: CreateSubscriptionModal,
                        data: {
                            userId: client.id,
                            userType: UserType.CLIENT,
                            subscriptionMode: SubscriptionMode.USER,
                            refetchOnSuccess: () =>
                                page === 1 ? handleLoadClientSubscriptionsPage(page) : () => setPage(1),
                        },
                    })
                }
            >
                Create subscription
            </Button>
        );
    };

    const renderSubscription = (subscription: SubscriptionResource) => {
        return (
            <Card compact className="mt-2" key={subscription.id}>
                {renderSubscriptionHeader(subscription)}
                {renderSubscriptionItems(subscription)}
                {renderSubscriptionCoupon(subscription)}
                {renderSubscriptionPaymentMethod(subscription)}
                {renderSubscriptionTrial(subscription)}
            </Card>
        );
    };

    const renderSubscriptions = () => {
        // Client subscriptions were NOT fetched yet
        if (!clientSubscriptions) {
            return (
                <Flex justify="center">
                    <Spinner />
                </Flex>
            );
        }

        if (clientSubscriptions.items.length === 0) {
            return (
                <NonIdealState
                    className="mt-2"
                    icon={<Icon className="mb-2" icon="search" size={70} />}
                    title={
                        <Heading className="mb-1" type="h4">
                            No subscriptions were found
                        </Heading>
                    }
                />
            );
        }

        const renderCollapseControl = (hiddenItemsAmount: number) => {
            let collapseButtonText = `+ ${hiddenItemsAmount} subscriptions more, click to show`;

            if (!isSubscriptionsCollapsed) {
                // Not collapsed
                collapseButtonText = `Hide subscription`;
            }

            return (
                <Button minimal onClick={() => setIsSubscriptionsCollapsed((prev) => !prev)}>
                    {collapseButtonText}
                </Button>
            );
        };

        return (
            <ListCollapse<SubscriptionResource>
                isCollapsed={isSubscriptionsCollapsed}
                maxSize={3}
                list={clientSubscriptions.items}
                itemRenderer={renderSubscription}
                renderCollapseControl={renderCollapseControl}
            />
        );
    };

    const renderPagination = () => {
        // Client brand profiles is not fetched yet
        if (clientSubscriptions === null) {
            return null;
        }

        // Check if offset paginator exist
        if (clientSubscriptions.paginator === null) {
            return;
        }

        // Only one page exist
        if (!clientSubscriptions.paginator.has_more && clientSubscriptions.paginator.offset === 0) {
            return null;
        }

        return (
            <Flex className="mt-2" justify="flex-end">
                <Pagination
                    fetching={isFetchingClientSubscriptions}
                    hasMore={clientSubscriptions.paginator.has_more}
                    page={page}
                    amountOfItemsOnPage={amountOfClientInvoicesOnPage}
                    totalItems={clientSubscriptions.paginator.total}
                    onPageChange={(newPage) => setPage(newPage)}
                />
            </Flex>
        );
    };

    return (
        <div {...props}>
            <Flex justify="space-between" align="center">
                <Heading type="h3">Subscriptions settings</Heading>
                {renderCreateSubscriptionButton()}
            </Flex>

            {renderSubscriptions()}
            {renderPagination()}
        </div>
    );
};

export default Subscriptions;
