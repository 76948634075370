import { ToastUtils } from '@app/data/utils';
import { useLogout } from '@app/hooks';
import { Intent } from '@blueprintjs/core';
import { HTTPErrorType } from 'dy-frontend-http-repository/lib/data/enums';
import { HTTPErrorData, HTTPPaymentErrorPayload } from 'dy-frontend-http-repository/lib/data/types';
import { repository } from 'dy-frontend-http-repository/lib/modules';
import { declineCodeInformation } from 'dy-frontend-shared/lib/data/consts';
import React, { useEffect } from 'react';

const HttpErrorHandlerProvider: React.FC = () => {
    const logout = useLogout();

    useEffect(() => {
        repository.addAxiosResponseInterceptor({
            onFail: (e) => {
                if (e.response) {
                    const error = e.response.data as HTTPErrorData;

                    if (error.type === HTTPErrorType.AUTH) {
                        // Log
                        console.error(error.message);

                        // Logout
                        logout();

                        // Show error message
                        ToastUtils.showToast({ message: 'Please login again', intent: Intent.DANGER });
                    } else if (error.type === HTTPErrorType.INPUT) {
                        // Log
                        console.error(error.message);

                        // Show error message
                        ToastUtils.showToast({
                            message: 'Error has occurred. Please refresh the page',
                            intent: Intent.DANGER,
                        });
                    } else if (error.type === HTTPErrorType.MISSING) {
                        // Log
                        console.error(error.message);
                    } else if (error.type === HTTPErrorType.FORBIDDEN) {
                        // Log
                        console.error(error.message);

                        // Show error message
                        ToastUtils.showToast({ message: 'Permission denied', intent: Intent.DANGER });
                    } else if (error.type === HTTPErrorType.UNPROCESSABLE) {
                        // Log
                        console.error(error.message);

                        // Show error message
                        ToastUtils.showToast({
                            message: 'Error has occurred. Please refresh the page',
                            intent: Intent.DANGER,
                        });
                    } else if (error.type === HTTPErrorType.PAYMENT) {
                        // Log
                        console.error(error.message);

                        // Get payload
                        const payload = e.response.data.payload as HTTPPaymentErrorPayload | null;
                        if (!payload) {
                            // Show error message
                            ToastUtils.showToast({
                                message: 'Unknown payment error occurred, code #403',
                                intent: Intent.DANGER,
                            });
                            return;
                        }

                        if (payload.last_error === null) {
                            // 3D secure
                            return;
                        }

                        // Get message by decline code
                        const declineCodeMessage = declineCodeInformation[payload.last_error.decline_code];

                        if (declineCodeMessage) {
                            // Existing decline code
                            ToastUtils.showToast({
                                message: declineCodeMessage,
                                intent: Intent.DANGER,
                            });
                        } else {
                            // Not existing decline code
                            ToastUtils.showToast({
                                message: 'Unknown payment error occurred, code #404',
                                intent: Intent.DANGER,
                            });
                        }
                    }
                }
            },
        });
    }, []);

    return <></>;
};

export default HttpErrorHandlerProvider;
