import { Button, Card, Colors, Elevation, Icon, Popover } from '@blueprintjs/core';
import Circle from '@components/Circle';
import Flex from '@components/Flex';
import DevText from '@components/Text';
import { AvailableCouponResource } from 'dy-frontend-http-repository/lib/modules/Coupon/resources';
import { CouponUtils } from 'dy-frontend-shared/lib/utils';
import React, { HTMLAttributes, useState } from 'react';
import ApplySubscriptionCouponForm from '../ApplySubscriptionCouponForm';

export interface ApplySubscriptionCouponControlProps {
    coupon: AvailableCouponResource | null;
    onChangeCoupon: (newCoupon: AvailableCouponResource | null) => void;
}

export type Props = HTMLAttributes<HTMLDivElement> & ApplySubscriptionCouponControlProps;

const ApplySubscriptionCouponControl: React.FC<Props> = ({ coupon, onChangeCoupon, ...props }) => {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const handleOpenCouponPopover = () => {
        if (coupon !== null) {
            return;
        }

        setIsPopoverOpen(true);
    };

    const handleCloseCouponPopover = () => {
        setIsPopoverOpen(false);
    };

    const handleRemoveCoupon = () => {
        if (coupon === null) {
            return;
        }

        onChangeCoupon(null);
    };

    const renderApplyCouponForm = () => {
        return (
            <Card>
                <ApplySubscriptionCouponForm
                    onSuccess={(coupon) => {
                        onChangeCoupon(coupon);
                        handleCloseCouponPopover();
                    }}
                />
            </Card>
        );
    };

    const renderCouponInformation = () => {
        if (!coupon) {
            return (
                <Flex align="center">
                    <Icon icon="tag" className="mr-1" color={Colors.GRAY2} />
                    <DevText muted>Apply coupon</DevText>
                </Flex>
            );
        }

        // Get duration text
        const couponDurationText = CouponUtils.getCouponDurationText(coupon);

        // Get price/percentage discount text
        const couponDiscountText = CouponUtils.getCouponDiscountText(coupon);

        return (
            <Flex align="center">
                <Flex align="center">
                    <Icon icon="tag" className="mr-1" />
                    <DevText>{coupon.title}</DevText>
                </Flex>

                <Circle className="mr-1 ml-1" size="6px" color={Colors.GRAY2} />

                <DevText muted>
                    {couponDiscountText}: {couponDurationText}
                </DevText>
            </Flex>
        );
    };

    const renderRemoveCouponButton = () => {
        if (coupon === null) {
            return null;
        }

        return <Button minimal icon="cross" onClick={handleRemoveCoupon} />;
    };

    const isCardInteractive = coupon === null;

    return (
        <Popover
            fill
            usePortal={false}
            isOpen={isPopoverOpen}
            content={renderApplyCouponForm()}
            onClose={handleCloseCouponPopover}
            {...props}
        >
            <Card compact interactive={isCardInteractive} elevation={Elevation.ONE} onClick={handleOpenCouponPopover}>
                <Flex justify="space-between" align="center">
                    {renderCouponInformation()}
                    {renderRemoveCouponButton()}
                </Flex>
            </Card>
        </Popover>
    );
};

export default ApplySubscriptionCouponControl;
