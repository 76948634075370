import PlanTag from '@app/containers/components/PlanTag';
import { Card, Colors, Icon, Tag } from '@blueprintjs/core';
import Avatar from '@components/Avatar';
import CommonInformationBlock from '@components/CommonInformationBlock';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import RouterLink from '@components/RouterLink';
import DevText from '@components/Text';
import { Endpoints, imageHashPreview } from '@data/consts';
import { $task } from '@pages/Task/store/states';
import { LicenceResource } from 'dy-frontend-http-repository/lib/modules/Task/resources';
import { ImageHashPreviewSize } from 'dy-frontend-shared/lib/data/valueObjects/ImageHashPreview/enums';
import { TextFormatUtils } from 'dy-frontend-shared/lib/utils';
import { useStore } from 'effector-react';
import moment from 'moment';
import React, { HTMLAttributes } from 'react';
import { useNavigate } from 'react-router-dom';

export type Props = HTMLAttributes<HTMLDivElement>;

const ClientInformation: React.FC<Props> = (props) => {
    const navigate = useNavigate();

    const task = useStore($task);

    if (!task) {
        return null;
    }

    // Get user
    const user = task.user;

    // Get avatar src
    let avatarSrc: string | null = null;
    if (user.image_hash) {
        avatarSrc = imageHashPreview.userImage(user.image_hash, ImageHashPreviewSize.SM);
    }

    const renderClientOverview = () => {
        return (
            <Flex align="center">
                <Avatar
                    className="mr-1"
                    width="35px"
                    height="35px"
                    src={avatarSrc}
                    alt={`${user.first_name} ${user.last_name}`}
                />

                <div>
                    <RouterLink
                        color={Colors.WHITE}
                        to={Endpoints.CLIENT_TASKS.replace(':clientId', user.id)}
                        fontWeight={600}
                    >
                        {user.first_name} {user.last_name}
                    </RouterLink>

                    <DevText muted>{user.company_name}</DevText>
                </div>
            </Flex>
        );
    };

    const renderBrandProfileInformation = () => {
        const brandProfile = task.brand_profile;

        if (!brandProfile) {
            return null;
        }

        return (
            <div className="mt-2">
                <DevText muted className="mb-small">
                    <Icon className="mr-1" icon="helper-management" size={16} color={Colors.GRAY2} />
                    Brand profile
                </DevText>

                <RouterLink
                    color={Colors.WHITE}
                    fontWeight={600}
                    to={Endpoints.BRAND_PROFILE.replace(':brandProfileId', `${brandProfile.id}`)}
                >
                    {brandProfile.title}
                </RouterLink>
            </div>
        );
    };

    const renderLastActivityInformation = () => {
        // TODO: add last activity whenever it's implemented on backend
        return (
            <CommonInformationBlock
                className="mt-2"
                icon="walk"
                title="Last activity"
                text={TextFormatUtils.capitalize(moment().fromNow())}
            />
        );
    };

    const renderWorkspaceInformation = () => {
        const workspace = user.workspace;

        if (!workspace) {
            return null;
        }

        return (
            <div className="mt-2">
                <DevText muted className="mb-small">
                    <Icon className="mr-1" icon="applications" size={16} color={Colors.GRAY2} />
                    Workspace
                </DevText>

                <RouterLink to={Endpoints.WORKSPACE.replace(':workspaceId', workspace.id)} fontWeight={600}>
                    {workspace.title}
                </RouterLink>
            </div>
        );
    };

    const renderPlanList = () => {
        const licences = user.licences;

        if (licences.length === 0) {
            return null;
        }

        const renderPlanListItem = (licence: LicenceResource, index: number) => {
            const plan = licence.plan;

            return (
                <PlanTag
                    id={plan.id}
                    key={licence.id}
                    className={index === 0 ? '' : 'mt-1'}
                    name={licence.plan.title}
                    quantity={licence.quantity}
                    color={plan.color}
                />
            );
        };

        return (
            <div className="mt-2">
                <DevText muted className="mb-small">
                    <Icon className="mr-1" icon="person" size={16} color={Colors.GRAY2} />
                    Plans
                </DevText>

                {licences.map(renderPlanListItem)}
            </div>
        );
    };

    return (
        <Card {...props}>
            <Heading className="mb-2" type="h4">
                Client
            </Heading>

            {renderClientOverview()}
            {renderBrandProfileInformation()}
            {renderWorkspaceInformation()}
            {renderPlanList()}
            {renderLastActivityInformation()}
        </Card>
    );
};

export default ClientInformation;
