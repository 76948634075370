import { useCustomSelectFormField, useForm } from '@app/hooks';
import { getStringNotEqualToStringValidator, getStringRequiredValidator } from '@app/hooks/validation/functions';
import { Button, Card, FormGroup, Intent, MenuItem, Spinner } from '@blueprintjs/core';
import { ItemRendererProps } from '@blueprintjs/select';
import Flex from '@components/Flex';
import Overlay from '@components/Overlay';
import Select from '@components/Select';
import { ModalProps } from '@modals/types';
import { PaymentMethodResource } from 'dy-frontend-http-repository/lib/modules/Subscription/resources';
import { useStore } from 'effector-react';
import React, { useEffect } from 'react';
import { fetchPaymentMethods, updateSubscriptionPaymentMethod } from './store/effects';
import { resetPaymentMethods } from './store/events';
import { $paymentMethods } from './store/states';
import Heading from '@components/Heading';

const subscriptionPaymentMethodValidators = [getStringRequiredValidator()];

export interface UpdateSubscriptionPaymentMethodModalProps {
    paymentAccountId: ID;
    subscriptionId: ID;
    selectedPaymentMethod: PaymentMethodResource | null;
    refetchOnSuccess?: () => Promise<unknown>;
}

type Props = ModalProps<UpdateSubscriptionPaymentMethodModalProps>;

const UpdateSubscriptionPaymentMethodModal: React.FC<Props> = ({ closeModal, data }) => {
    if (data && data.selectedPaymentMethod) {
        subscriptionPaymentMethodValidators.push(
            getStringNotEqualToStringValidator({
                stringToCompareWith: data.selectedPaymentMethod.id,
                stringNotEqualToStringMessage: 'This payment method is already selected',
            })
        );
    }

    const paymentMethods = useStore($paymentMethods);

    const subscriptionPaymentMethod = useCustomSelectFormField<PaymentMethodResource | null>({
        id: 'subscription-payment-method',
        formatValue: (paymentMethod) => (paymentMethod ? paymentMethod.id : ''),
        initialValue: data && data.selectedPaymentMethod ? data.selectedPaymentMethod : null,
        validators: subscriptionPaymentMethodValidators,
    });

    const form = useForm({
        fields: [subscriptionPaymentMethod],
        apiCall: async () => {
            try {
                // Update subscription
                const subscriptionRef = await updateSubscriptionPaymentMethod({
                    subscriptionId: data!.subscriptionId,
                    paymentMethodId: subscriptionPaymentMethod.value!.id,
                });

                // Refetch if refetch function provided
                if (data?.refetchOnSuccess) {
                    const refetchResponse = await data!.refetchOnSuccess();
                }

                return { response: subscriptionRef };
            } catch (e) {
                throw e;
            }
        },
        onSuccess: () => {
            closeModal?.();
        },
        onFailure: (e) => {
            // TODO: handle error
            console.error(e);
        },
    });

    useEffect(() => {
        if (!data) {
            return;
        }

        fetchPaymentMethods(data.paymentAccountId).catch((e) => {
            // TODO: handle error
            console.error(e);
        });
    }, []);

    useEffect(() => {
        return () => {
            resetPaymentMethods();
        };
    }, []);

    if (!data) {
        closeModal?.();
        return null;
    }

    const renderPaymentMethodSelect = () => {
        const renderItem = (item: PaymentMethodResource, { handleClick }: ItemRendererProps) => {
            const isMenuItemActive = item.id === subscriptionPaymentMethod.value?.id;

            return (
                <MenuItem
                    active={isMenuItemActive}
                    key={item.id}
                    text={`${item.card_brand.toUpperCase()} ending in *${item.card_last_four}`}
                    label={`${`${item.card_expiration_month}`.padStart(2, '0')}/${item.card_expiration_year}`}
                    onClick={handleClick}
                />
            );
        };

        const handleItemSelect = (item: PaymentMethodResource) => {
            subscriptionPaymentMethod.handleChange(item);
        };

        return (
            <FormGroup
                label="Payment method"
                intent={subscriptionPaymentMethod.error ? Intent.DANGER : Intent.NONE}
                helperText={subscriptionPaymentMethod.error ?? ''}
            >
                <Select<PaymentMethodResource>
                    items={paymentMethods!.items}
                    itemRenderer={renderItem}
                    onItemSelect={handleItemSelect}
                    popoverProps={{
                        matchTargetWidth: true,
                        usePortal: false,
                    }}
                    selectButtonProps={{
                        fill: true,
                        rightIcon: 'double-caret-vertical',
                        placeholder: 'Select payment method',
                        text:
                            subscriptionPaymentMethod.value &&
                            `${subscriptionPaymentMethod.value.card_brand.toUpperCase()} ending in *${
                                subscriptionPaymentMethod.value.card_last_four
                            }`,
                        label:
                            subscriptionPaymentMethod.value &&
                            `${`${subscriptionPaymentMethod.value.card_expiration_month}`.padStart(2, '0')}/${
                                subscriptionPaymentMethod.value.card_expiration_year
                            }`,
                    }}
                />
            </FormGroup>
        );
    };

    const renderContent = () => {
        if (!paymentMethods) {
            // Payment method are not fetched yet
            return (
                <Flex align="center" justify="center">
                    <Spinner />
                </Flex>
            );
        }

        return (
            <Card style={{ width: '600px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Change payment method</Heading>
                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <form onSubmit={form.handleFormSubmit}>
                    {renderPaymentMethodSelect()}

                    <Flex className="mt-2" justify="flex-end">
                        <Button className="mr-1" outlined onClick={closeModal}>
                            Cancel
                        </Button>

                        <Button loading={form.isSubmitting} disabled={form.hasFieldErrors} type="submit">
                            Update payment method
                        </Button>
                    </Flex>
                </form>
            </Card>
        );
    };

    return (
        <Overlay isOpen onClose={closeModal}>
            {renderContent()}
        </Overlay>
    );
};

export default UpdateSubscriptionPaymentMethodModal;
