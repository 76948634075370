import React, { HTMLAttributes, useEffect } from 'react';
import { Spinner } from '@blueprintjs/core';
import { useStore } from 'effector-react';
import { $userIdentity } from './store/states';
import { fetchUserIdentity } from './store/effects';
import { resetUserIdentity } from './store/events';
import Flex from '@components/Flex';
import AuthenticationMethodList from './components/AuthenticationMethodList';
import DangerZone from './components/DangerZone';
import EmailManagement from './components/EmailManagement';
import VerificationManagement from './components/VerificationManagement';
import { UserType } from 'dy-frontend-http-repository/lib/data/enums';
import { $permissions } from '@containers/store/states';

export interface UserSettingsProps {
    userId: ID | null;
    onChangeEmailSuccess: (email: string) => void;
    onVerifyUserSuccess?: () => void;
    onRemoveUserVerificationSuccess?: () => void;
}

type Props = HTMLAttributes<HTMLDivElement> & UserSettingsProps;

const UserSettings: React.FC<Props> = ({
    userId,
    onVerifyUserSuccess,
    onChangeEmailSuccess,
    onRemoveUserVerificationSuccess,
    ...props
}) => {
    const userIdentity = useStore($userIdentity);

    useEffect(() => {
        if (!userId) {
            // User is NOT fetched yet
            return;
        }

        if (userIdentity && userIdentity.user_id === userId) {
            // User identity have already been fetched & userId did NOT change
            return;
        }

        // TODO: Handle error
        fetchUserIdentity(userId).catch(console.error);
    }, [userId, userIdentity]);

    useEffect(() => {
        return () => {
            resetUserIdentity();
        };
    }, []);

    if (!userId) {
        return null;
    }

    if (!userIdentity) {
        return (
            <Flex justify="center">
                <Spinner className="mt-3" />
            </Flex>
        );
    }

    return (
        <div {...props}>
            <VerificationManagement
                className="mb-3"
                onVerifyUserSuccess={onVerifyUserSuccess}
                onRemoveUserVerificationSuccess={onRemoveUserVerificationSuccess}
            />
            <EmailManagement className="mb-3" onChangeEmailSuccess={onChangeEmailSuccess} />
            <AuthenticationMethodList className="mb-3" />
            <DangerZone />
        </div>
    );
};

export default UserSettings;
