import repository from 'dy-frontend-http-repository/lib/repository';
import { SubscriptionResource } from 'dy-frontend-http-repository/lib/modules/Subscription/resources';
import { SubscriptionRef } from 'dy-frontend-http-repository/lib/modules/Subscription/refs';
import domain from './domain';

// Fetch single subscription
export const fetchSubscription = domain.createEffect<ID, SubscriptionResource>({
    name: 'fetchSubscription',
    handler: async (subscriptionId) => {
        return await repository.subscription().getById(subscriptionId);
    },
});

// Do not cancel subscription
export const doNotCancelSubscription = domain.createEffect<ID, SubscriptionRef>({
    name: 'doNotCancelSubscription',
    handler: async (subscriptionId) => {
        return await repository.subscription().doNotCancelSubscription(subscriptionId);
    },
});

// Do not pause subscription
export const removeSubscriptionPause = domain.createEffect<ID, SubscriptionRef>({
    name: 'removeSubscriptionPause',
    handler: async (subscriptionId) => {
        return await repository.subscription().removePause(subscriptionId);
    },
});

// Remove all scheduled updates
export const removeSubscriptionScheduledUpdates = domain.createEffect<ID, SubscriptionRef>({
    name: 'removeSubscriptionScheduledUpdates',
    handler: async (subscriptionId) => {
        return await repository.subscription().cancelScheduledUpdates(subscriptionId);
    },
});
