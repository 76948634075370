import { TaskClientFeedbackFilterInput } from 'dy-frontend-http-repository/lib/modules/TaskClientFeedback/inputs';
import { useStore } from 'effector-react';
import React, { useEffect, useState } from 'react';
import { $team } from '../../store/states';
import { amountOfTaskClientFeedbacksOnPage } from './consts';
import { fetchTaskClientFeedback, fetchTaskClientFeedbackSummary } from './store/effects';
import { $taskClientFeedback, $taskClientFeedbackSummary } from './store/states';
import TaskClientFeedback from '@containers/components/TaskClientFeedback';
import Flex from '@app/components/Flex';
import { Spinner } from '@blueprintjs/core';
import Pagination from '@app/components/Pagination';
import { resetTaskClientFeedback, resetTaskClientFeedbackSummary } from './store/events';
import { usePageTitle } from '@app/hooks';

const TeamTaskFeedback: React.FC = () => {
    usePageTitle('Team Request Feedback');

    const team = useStore($team);
    const taskClientFeedback = useStore($taskClientFeedback);
    const taskClientFeedbackSummary = useStore($taskClientFeedbackSummary);
    const isFetchingTaskClientFeedback = useStore(fetchTaskClientFeedback.pending);

    const [page, setPage] = useState(1);

    const handleLoadPage = async (newPage: number) => {
        if (!team) return;

        // Get page offset
        const pageOffset = (newPage - 1) * amountOfTaskClientFeedbacksOnPage;

        // Get task client feedback filter
        const filter: TaskClientFeedbackFilterInput = {
            team_id: [team.id],
        };

        try {
            // Fetch client feedback
            await fetchTaskClientFeedback({
                pagination: {
                    _pagination: { limit: amountOfTaskClientFeedbacksOnPage, offset: pageOffset },
                },
                filter,
            });

            // Fetch client summary
            await fetchTaskClientFeedbackSummary({ filter });
        } catch (e) {
            // TODO: handle error
            console.error(e);
        }
    };

    useEffect(() => {
        handleLoadPage(page);

        // eslint-disable-next-line
    }, [page]);

    useEffect(() => {
        return () => {
            resetTaskClientFeedback();
            resetTaskClientFeedbackSummary();
        };
    }, []);

    if (!team) {
        return null;
    }

    if (!taskClientFeedback || !taskClientFeedbackSummary) {
        return (
            <Flex direction="row" justify="center">
                <Spinner />
            </Flex>
        );
    }

    const renderPagination = () => {
        // Admins were not fetched yet
        if (taskClientFeedback === null) {
            return null;
        }

        // Check if offset paginator exist
        if (taskClientFeedback.paginator === null) {
            return;
        }

        // Only one page exist
        if (!taskClientFeedback.paginator.has_more && taskClientFeedback.paginator.offset === 0) {
            return null;
        }

        return (
            <Flex justify="flex-end">
                <Pagination
                    fetching={isFetchingTaskClientFeedback}
                    hasMore={taskClientFeedback.paginator.has_more}
                    className="mt-2"
                    page={page}
                    amountOfItemsOnPage={amountOfTaskClientFeedbacksOnPage}
                    totalItems={taskClientFeedback.paginator.total}
                    onPageChange={(newPage) => setPage(newPage)}
                />
            </Flex>
        );
    };

    return (
        <div>
            <TaskClientFeedback
                loading={isFetchingTaskClientFeedback}
                taskClientFeedback={taskClientFeedback.items}
                taskClientFeedbackSummary={taskClientFeedbackSummary}
            />

            {/* Pagination */}
            {renderPagination()}
        </div>
    );
};

export default TeamTaskFeedback;
