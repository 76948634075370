import React, { HTMLAttributes, useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { useLocation } from 'react-router-dom';
import { Button, Card, Collapse, Divider, IconName } from '@blueprintjs/core';
import { Endpoints } from '@data/consts';
import { openModal } from '@modals/store/events';
import { $task } from '../../store/states';
import Flex from '@components/Flex';
import Brief from './components/Brief';
import DesignExtensions from './components/DesignExtensions';
import DesignDimensions from './components/DesignDimensions';
import { $permissions } from '@app/containers/store/states';
import Heading from '@components/Heading';
import UpdateTaskInformationModal from '../../pages/TaskInformation/modals/UpdateTaskInformationModal';
import { TaskPermission } from 'dy-frontend-permissions/lib/permission';

export type Props = HTMLAttributes<HTMLDivElement>;

const Information: React.FC<Props> = (props) => {
    const permissions = useStore($permissions);

    const location = useLocation();
    const task = useStore($task);

    const [isInformationCollapsed, setIsInformationCollapsed] = useState(true);

    useEffect(() => {
        // Collapse is opened by default only on TASK_INFORMATION page

        setIsInformationCollapsed(
            location.pathname !== Endpoints.TASK_INFORMATION.replace(':taskId', `${task!.id}`),
        );

        // eslint-disable-next-line
    }, [location]);

    const isInformationVisible =
        task && location.pathname !== Endpoints.TASK_MANAGEMENT.replace(':taskId', `${task.id}`);
    if (!isInformationVisible) {
        return null;
    }

    const renderUpdateTask = () => {
        const requiredUpdatePermission = task?.publish === null ?
            TaskPermission.BRIEF_UPDATE_UNPUBLISHED
            : TaskPermission.BRIEF_UPDATE_PUBLISHED;

        const isTaskUpdateAllowed = permissions.isRoot.task || permissions.has(requiredUpdatePermission);
        if (!isTaskUpdateAllowed) {
            return null;
        }

        return (
            <Button minimal icon="edit" onClick={() => openModal({ ModalComponent: UpdateTaskInformationModal })}>
                Edit
            </Button>
        );
    };

    const renderHeader = () => {
        const collapseButtonIcon: IconName = isInformationCollapsed ? 'chevron-down' : 'chevron-up';

        return (
            <Flex align="center" justify="space-between">
                <Heading type="h4">Brief</Heading>

                <Flex>
                    {renderUpdateTask()}

                    <Button
                        className="ml-1"
                        icon={collapseButtonIcon}
                        onClick={() => setIsInformationCollapsed((prevValue) => !prevValue)}
                    />
                </Flex>
            </Flex>
        );
    };

    const renderContent = () => {
        return (
            <Collapse isOpen={!isInformationCollapsed}>
                <Brief className="mb-2" />
                <Divider className="mb-2" />
                <DesignExtensions className="mb-2" />
                <Divider className="mb-2" />
                <DesignDimensions />
            </Collapse>
        );
    };

    return (
        <Card {...props}>
            {renderHeader()}
            {renderContent()}
        </Card>
    );
};

export default Information;
