import { TaskDeliverableResource } from 'dy-frontend-http-repository/lib/modules/TaskDeliverable/resources';
import { TaskDeliverableCommentResource } from 'dy-frontend-http-repository/lib/modules/TaskDeliverableComment/resources';
import domain from './domain';

// Set selected task deliverable
export const setSelectedTaskDeliverable = domain.createEvent<TaskDeliverableResource>('setSelectedTaskDeliverable');

// Set point marker
export const setPointMarker = domain.createEvent<TaskDeliverableCommentResource['marker_point']>('setPointMarker');

// Set time marker
export const setTimeMarker = domain.createEvent<TaskDeliverableCommentResource['marker_time']>('setTimeMarker');

// Set highlighted task deliverable comment id
export const setHighlightedTaskDeliverableCommentId = domain.createEvent<ID | null>(
    'setHighlightedTaskDeliverableCommentId'
);

// SEt queued video timeline position
export const setQueuedVideoTimelinePositionMS = domain.createEvent<number | null>('setQueuedVideoTimelinePositionMS');

// Reset task deliverable comments
export const resetTaskDeliverableComments = domain.createEvent('resetTaskDeliverableComments');

// Reset root task deliverable comments
export const resetRootTaskDeliverableCommentsByIdMap = domain.createEvent('resetRootTaskDeliverableCommentsByIdMap');

// Reset selected task deliverable
export const resetSelectedTaskDeliverable = domain.createEvent('resetSelectedTaskDeliverable');

// Reset point marker
export const resetPointMarker = domain.createEvent('resetPointMarker');

// Reset time marker
export const resetTimeMarker = domain.createEvent('resetTimeMarker');

// Reset point marker map
export const resetPointMarkerByTaskDeliverableCommentIdMap = domain.createEvent(
    'resetPointMarkerByTaskDeliverableCommentIdMap'
);

// Reset highlighted task deliverable comment id
export const resetHighlightedTaskDeliverableCommentId = domain.createEvent('resetHighlightedTaskDeliverableCommentId');

// Reset queued video timeline position
export const resetQueuedVideoTimelinePositionMS = domain.createEvent('resetQueuedVideoTimelinePositionMS');
