import { useCustomSelectFormField, useForm, useTextFormField } from '@app/hooks';
import {
    getStringMaxLengthValidator,
    getStringMinLengthValidator,
    getStringNumericValueValidator,
    getStringRequiredValidator,
} from '@app/hooks/validation/functions';
import { FormField } from '@app/hooks/validation/types';
import { Button, Checkbox, Divider, FormGroup, Intent, MenuItem, Radio } from '@blueprintjs/core';
import { ItemRendererProps } from '@blueprintjs/select';
import Flex from '@components/Flex';
import InputFormField from '@components/InputFormField';
import Select from '@components/Select';
import { $authorizedUser, $permissions } from '@containers/store/states';
import { taskApi, taskStateTransitionLogApi } from '@pages/Task/store/apis';
import { $clientPaymentAccount, $task } from '@pages/Task/store/states';
import { TaskState } from 'dy-frontend-http-repository/lib/data/enums';
import { PaymentMethodResource } from 'dy-frontend-http-repository/lib/modules/PaymentAccount/resources';
import { TaskPublishOutputQueue } from 'dy-frontend-http-repository/lib/modules/TaskPublish/enums';
import { PublishTaskPublishPaymentInput } from 'dy-frontend-http-repository/lib/modules/TaskPublish/inputs';
import { useStore } from 'effector-react';
import moment from 'moment';
import React, { useState } from 'react';
import { PublishTaskStep } from '../../enums';
import { useTaskPublish } from '../../hooks';
import { changeStep } from '../../store/events';
import { CommonStepProps } from '../../types';
import { AmountType } from './types';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import { TaskPublishPermission } from 'dy-frontend-permissions/lib/permission';

export type Props = CommonStepProps;

const paymentTaskPublishUSDAmountValidators = [getStringRequiredValidator(), getStringNumericValueValidator()];
const paymentTaskPublishStripeInvoiceIdValidators = [getStringRequiredValidator()];
const paymentTaskPublishPaymentMethodIdValidators = [getStringRequiredValidator()];
const paymentTaskPublishCommentValidators = [
    getStringRequiredValidator(),
    getStringMinLengthValidator({ minStringLength: 5 }),
    getStringMaxLengthValidator({ maxStringLength: 100 }),
];

const PublishTaskViaPaymentStep: React.FC<Props> = ({ closeModal, data }) => {
    const permissions = useStore($permissions);

    const { handlePublishTaskViaPayment } = useTaskPublish({
        taskId: data.taskId,
        shouldCheckTaskPublishChoices: false,
    });

    const task = useStore($task);
    const me = useStore($authorizedUser);
    const clientPaymentAccount = useStore($clientPaymentAccount);

    const [amountType, setAmountType] = useState<AmountType | null>(AmountType.TASK_CATEGORY);
    const [publishOutputQueue, setPublishOutputQueue] = useState<TaskPublishOutputQueue>(TaskPublishOutputQueue.ACTIVE);

    const paymentTaskPublishUsdAmount = useTextFormField({
        id: 'payment-task-publish-usd-amount',
        validators: paymentTaskPublishUSDAmountValidators,
        initialValue: '',
    });

    const paymentTaskPublishStripeInvoiceId = useTextFormField({
        id: 'payment-task-publish-stripe-invoice-id',
        validators: paymentTaskPublishStripeInvoiceIdValidators,
        initialValue: '',
    });

    const paymentTaskPublishPaymentMethodId = useCustomSelectFormField<ID | null>({
        id: 'payment-task-publish-stripe-payment-method-id',
        validators: paymentTaskPublishPaymentMethodIdValidators,
        initialValue: '',
        formatValue: (value) => value ?? '',
    });

    const paymentTaskPublishComment = useTextFormField({
        id: 'payment-task-publish-comment',
        validators: paymentTaskPublishCommentValidators,
        initialValue: '',
    });

    const getFormFields = () => {
        const fields: FormField[] = [paymentTaskPublishComment];

        switch (amountType) {
            case AmountType.TASK_CATEGORY:
                fields.push(paymentTaskPublishPaymentMethodId);
                break;
            case AmountType.MANUAL_AMOUNT:
                fields.push(paymentTaskPublishUsdAmount);
                fields.push(paymentTaskPublishPaymentMethodId);
                break;
            case AmountType.STRIPE_INVOICE:
                fields.push(paymentTaskPublishStripeInvoiceId);
                break;
        }

        return fields;
    };

    const form = useForm({
        fields: getFormFields(),
        apiCall: async () => {
            try {
                // Create common task publish payment input
                let taskPublishInput: PublishTaskPublishPaymentInput = {
                    output_queue: publishOutputQueue,
                    task_id: data.taskId,
                    comment: paymentTaskPublishComment.value,
                };

                // Add specific fields to task publish input depending on chosen amount type
                switch (amountType) {
                    case AmountType.MANUAL_AMOUNT:
                        taskPublishInput = {
                            ...taskPublishInput,
                            is_custom: true,
                            amount: Math.trunc(parseFloat(paymentTaskPublishUsdAmount.value) * 100),
                            payment_method_id: paymentTaskPublishPaymentMethodId.value as ID,
                            currency: clientPaymentAccount ? clientPaymentAccount.currency : '',
                        };
                        break;
                    case AmountType.STRIPE_INVOICE:
                        taskPublishInput = {
                            ...taskPublishInput,
                            invoice_external_id: paymentTaskPublishStripeInvoiceId.value,
                        };
                        break;
                    case AmountType.TASK_CATEGORY:
                        taskPublishInput = {
                            ...taskPublishInput,
                            payment_method_id: paymentTaskPublishPaymentMethodId.value as ID,
                        };
                        break;
                }

                // Publish task
                const momentNowUtc = moment().utc().format();
                const response = await handlePublishTaskViaPayment(taskPublishInput);
                taskApi.transitionState({ state: TaskState.PUBLISHED });
                taskStateTransitionLogApi.update([
                    {
                        comment: paymentTaskPublishComment.value,
                        applied_at: momentNowUtc,
                        is_forced: false,
                        state: TaskState.PUBLISHED,
                        user: {
                            first_name: me!.user.first_name,
                            id: me!.user.id,
                            last_name: me!.user.last_name,
                            image_hash: me!.user.image_hash,
                            type: me!.user.type,
                        },
                    },
                ]);

                return { response };
            } catch (e) {
                throw e;
            }
        },
        onSuccess: () => {
            closeModal?.();
        },
        onFailure: (error) => {
            // TODO: handle error
            console.error(error);
        },
    });

    if (!task) {
        return null;
    }

    if (!me) {
        return null;
    }

    const renderAmountBlock = () => {
        const renderTaskCategoryRadio = () => {
            const radioLabel = `"${task.category.title}" request category price - TODO: price USD`;
            return (
                <Radio
                    checked={amountType === AmountType.TASK_CATEGORY}
                    className="mb-1"
                    label={radioLabel}
                    onChange={() => setAmountType(AmountType.TASK_CATEGORY)}
                />
            );
        };

        const isCustomAllowed = permissions.isRoot.taskPublish || permissions.has(TaskPublishPermission.CREATE_CUSTOM);

        const renderManualUSDAmountRadio = () => {
            if (!isCustomAllowed) {
                return null;
            }

            return (
                <Radio
                    className="mb-1"
                    checked={amountType === AmountType.MANUAL_AMOUNT}
                    label="Manual amount"
                    onChange={() => setAmountType(AmountType.MANUAL_AMOUNT)}
                />
            );
        };

        const renderManualUSDAmountInput = () => {
            // Show manual USD amount input only whenever <code>amountType === AmountType.MANUAL</code>
            if (amountType !== AmountType.MANUAL_AMOUNT) {
                return null;
            }

            return (
                <InputFormField
                    field={paymentTaskPublishUsdAmount}
                    formGroupProps={{
                        label: `Amount (${clientPaymentAccount ? clientPaymentAccount.currency : 'Unknown'})`,
                    }}
                    inputProps={{ placeholder: 'Enter amount of USD' }}
                />
            );
        };

        const renderStripeIdRadio = () => {
            if (!isCustomAllowed) {
                return null;
            }

            return (
                <Radio
                    className="mb-1"
                    checked={amountType === AmountType.STRIPE_INVOICE}
                    label="Use already paid Stripe invoice"
                    onChange={() => setAmountType(AmountType.STRIPE_INVOICE)}
                />
            );
        };

        const renderStripeIdInput = () => {
            // Show manual USD amount input only whenever <code>amountType === AmountType.MANUAL</code>
            if (amountType !== AmountType.STRIPE_INVOICE) {
                return null;
            }

            return (
                <InputFormField
                    field={paymentTaskPublishStripeInvoiceId}
                    formGroupProps={{ label: 'Stripe invoice ID' }}
                    inputProps={{ placeholder: 'Enter stripe invoice ID' }}
                />
            );
        };

        return (
            <div>
                <DevText className="mb-1">Amount</DevText>

                {/* Task category credit price */}
                {renderTaskCategoryRadio()}

                {/* Manual amount of USD */}
                {renderManualUSDAmountRadio()}
                {renderManualUSDAmountInput()}

                {/* Stripe invoice ID*/}
                {renderStripeIdRadio()}
                {renderStripeIdInput()}
            </div>
        );
    };

    const renderPaymentMethodSelect = () => {
        // Do not allow choosing payment method if <code>amountType === STRIPE_INVOICE</code>
        if (amountType === AmountType.STRIPE_INVOICE) {
            return null;
        }

        let selectButtonText: string | null = null;
        if (paymentTaskPublishPaymentMethodId.value && clientPaymentAccount) {
            const foundClientPaymentMethod = clientPaymentAccount.payment_methods.find(
                (p) => p.id === paymentTaskPublishPaymentMethodId.value,
            );
            if (foundClientPaymentMethod) {
                selectButtonText = `${foundClientPaymentMethod.card_brand} **** ${foundClientPaymentMethod.card_last_four}`;
            }
        }

        const renderItem = (item: PaymentMethodResource, { handleClick }: ItemRendererProps) => {
            const isMenuItemActive = item.id === paymentTaskPublishPaymentMethodId.value;

            return (
                <MenuItem
                    active={isMenuItemActive}
                    key={item.id}
                    text={`${item.card_brand} **** ${item.card_last_four}`}
                    onClick={handleClick}
                />
            );
        };

        const handleItemSelect = (item: PaymentMethodResource) => {
            paymentTaskPublishPaymentMethodId.handleChange(item.id);
        };

        return (
            <FormGroup
                label="Payment method"
                intent={!!paymentTaskPublishPaymentMethodId.error ? Intent.DANGER : Intent.NONE}
                helperText={paymentTaskPublishPaymentMethodId.error}
            >
                <Select<PaymentMethodResource>
                    filterable
                    disabled={!clientPaymentAccount || clientPaymentAccount.payment_methods.length === 0}
                    items={clientPaymentAccount ? clientPaymentAccount.payment_methods : []}
                    itemRenderer={renderItem}
                    onItemSelect={handleItemSelect}
                    popoverProps={{
                        matchTargetWidth: true,
                        usePortal: false,
                    }}
                    selectButtonProps={{
                        fill: true,
                        rightIcon: 'double-caret-vertical',
                        placeholder: 'Select payment method',
                        text: selectButtonText,
                    }}
                />
            </FormGroup>
        );
    };

    const renderPublishOutputQueueCheckbox = () => {
        return (
            <Checkbox
                checked={publishOutputQueue === TaskPublishOutputQueue.BACKLOG}
                label='Publish to "Backlog"'
                onChange={() =>
                    setPublishOutputQueue((prevValue) =>
                        prevValue === TaskPublishOutputQueue.ACTIVE
                            ? TaskPublishOutputQueue.BACKLOG
                            : TaskPublishOutputQueue.ACTIVE,
                    )
                }
            />
        );
    };

    const renderFormControls = () => {
        return (
            <Flex justify="flex-end">
                <Button className="mr-1" outlined onClick={() => changeStep(PublishTaskStep.PUBLISH_METHOD_SELECT)}>
                    Select other method
                </Button>
                <Button
                    disabled={form.hasFieldErrors}
                    loading={form.isSubmitting}
                    type="submit"
                    intent={Intent.PRIMARY}
                >
                    Publish
                </Button>
            </Flex>
        );
    };

    return (
        <div>
            <Flex className="mb-2" align="center" justify="space-between">
                <Heading type="h4">Payment publish</Heading>
                <Button minimal icon="cross" onClick={closeModal} />
            </Flex>

            <Divider className="mb-2" />

            <form onSubmit={form.handleFormSubmit}>
                {renderAmountBlock()}
                {renderPaymentMethodSelect()}
                <InputFormField
                    field={paymentTaskPublishComment}
                    formGroupProps={{ label: 'Publish comment' }}
                    inputProps={{ placeholder: 'Enter publish comment' }}
                />
                {renderPublishOutputQueueCheckbox()}
                {renderFormControls()}
            </form>
        </div>
    );
};

export default PublishTaskViaPaymentStep;
