import Flex from '@app/components/Flex';
import Heading from '@app/components/Heading';
import Overlay from '@app/components/Overlay';
import SelectFormField from '@app/components/SelectFormField';
import { ModalProps } from '@app/containers/modals/types';
import { useCustomSelectFormField, useForm } from '@app/hooks';
import { getStringRequiredValidator } from '@app/hooks/validation/functions';
import { Card, Button, Divider, Intent, FormGroup, MenuItem } from '@blueprintjs/core';
import { attachUserAuthRole } from '../../store/effects';
import React from 'react';
import { AuthRoleListItemResource } from 'dy-frontend-http-repository/lib/modules/AuthRole/resources';
import { ItemRendererProps } from '@blueprintjs/select';
import Select from '@app/components/Select';
import { userAuthRolesApi } from '../../store/apis';

const roleIdValidators = [getStringRequiredValidator()];

export interface AddUserAuthRoleModalProps {
    userId: ID;
    assignableRoles: AuthRoleListItemResource[];
}

type Props = ModalProps<AddUserAuthRoleModalProps>;

const AddUserAuthRoleModal: React.FC<Props> = ({ closeModal, data }) => {
    const selectedRole = useCustomSelectFormField<AuthRoleListItemResource | null>({
        id: 'selected-auth-role',
        validators: roleIdValidators,
        initialValue: null,
        formatValue: (value) => (value ? value.id : ''),
    });

    const form = useForm({
        fields: [selectedRole],
        apiCall: async () => {
            if (!selectedRole.value) {
                throw new Error('Role is NOT selected');
            }

            try {
                // Attach user auth role
                await attachUserAuthRole({
                    userId: data?.userId ?? '0',
                    roleId: selectedRole.value.id,
                });

                // Add user auth role in state
                userAuthRolesApi.add({ userId: data?.userId ?? '0', authRole: selectedRole.value });

                return { response: true };
            } catch (e) {
                throw e;
            }
        },
        onSuccess: () => {
            closeModal?.();
        },
        onFailure: (error) => {
            // TODO: handle error
            console.error(error);
        },
    });

    if (!data) {
        closeModal?.();
        return null;
    }

    const renderRoleIdSelect = () => {
        let selectButtonText: string | null = null;
        if (selectedRole.value) {
            selectButtonText = selectedRole.value.title;
        }

        const renderItem = (item: AuthRoleListItemResource, { handleClick }: ItemRendererProps) => {
            const isMenuItemActive = item.id === selectedRole.value?.id;

            return <MenuItem active={isMenuItemActive} key={item.id} text={item.title} onClick={handleClick} />;
        };

        const handleItemSelect = (item: AuthRoleListItemResource) => {
            selectedRole.handleChange(item);
        };

        return (
            <FormGroup
                label="Auth Role"
                intent={!!selectedRole.error ? Intent.DANGER : Intent.NONE}
                helperText={selectedRole.error}
            >
                <Select<AuthRoleListItemResource>
                    items={data.assignableRoles}
                    itemRenderer={renderItem}
                    onItemSelect={handleItemSelect}
                    popoverProps={{
                        matchTargetWidth: true,
                        usePortal: false,
                    }}
                    selectButtonProps={{
                        fill: true,
                        rightIcon: 'double-caret-vertical',
                        placeholder: 'Select auth role',
                        text: selectButtonText,
                    }}
                />
            </FormGroup>
        );
    };

    return (
        <Overlay isOpen onClose={closeModal}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Add auth role</Heading>
                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <Divider className="mb-2" />

                <form onSubmit={form.handleFormSubmit}>
                    {renderRoleIdSelect()}

                    <Flex className="mt-2" justify="flex-end">
                        <Button className="mr-1" outlined onClick={closeModal}>
                            Cancel
                        </Button>

                        <Button
                            loading={form.isSubmitting}
                            disabled={form.isSubmitting || form.hasFieldErrors}
                            type="submit"
                            intent={Intent.PRIMARY}
                        >
                            Add role
                        </Button>
                    </Flex>
                </form>
            </Card>
        </Overlay>
    );
};

export default AddUserAuthRoleModal;
