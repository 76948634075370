import { useCustomMultiSelectFormField, useForm } from '@app/hooks';
import { ReturnValue } from '@app/hooks/validation/useCustomMultiSelectFormField';
import { Button, Card, Divider, FormGroup, Intent, MenuItem } from '@blueprintjs/core';
import { ItemPredicate, ItemRendererProps, MultiSelect } from '@blueprintjs/select';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import Overlay from '@components/Overlay';
import { popularFonts } from '@data/consts';
import { ModalProps } from '@modals/types';
import { updateBrandProfileInformation } from '@pages/BrandProfile/store/effects';
import { BrandProfileRef } from 'dy-frontend-http-repository/lib/modules/BrandProfile/refs';
import { BrandProfileFontPrefix } from 'dy-frontend-shared/lib/data/enums';
import React from 'react';
import { brandProfileApi } from '../../store/apis';

export interface UpdateBrandProfileFontsModalProps {
    brandProfileId: ID;
    fonts: string[];
}

type Props = ModalProps<UpdateBrandProfileFontsModalProps>;

const fontValidators = [];

const getSelectedItemIndex = (item: string, items: string[]) => {
    return items.findIndex((i) => i === item);
};

const UpdateBrandProfileFontsModal: React.FC<Props> = ({ closeModal, data }) => {
    const primaryFonts = useCustomMultiSelectFormField<string>({
        id: 'brand-profile-primary-fonts',
        validators: fontValidators,
        initialValue:
            data?.fonts && data.fonts.length > 0
                ? data.fonts
                      .filter((font) => font.startsWith(BrandProfileFontPrefix.PRIMARY))
                      .map((font) => font.split(BrandProfileFontPrefix.PRIMARY)[1])
                : [],
        getSelectedItemIndex,
    });

    const secondaryFonts = useCustomMultiSelectFormField<string>({
        id: 'brand-profile-secondary-fonts',
        validators: fontValidators,
        initialValue:
            data?.fonts && data.fonts.length > 0
                ? data.fonts
                      .filter((font) => font.startsWith(BrandProfileFontPrefix.SECONDARY))
                      .map((font) => font.split(BrandProfileFontPrefix.SECONDARY)[1])
                : [],
        getSelectedItemIndex,
    });

    const additionalFonts = useCustomMultiSelectFormField<string>({
        id: 'brand-profile-additional-fonts',
        validators: fontValidators,
        initialValue: data?.fonts && data.fonts.length > 0
            ? data.fonts.filter(
                  (font) =>
                      !font.startsWith(BrandProfileFontPrefix.PRIMARY) &&
                      !font.startsWith(BrandProfileFontPrefix.SECONDARY)
              )
            : [],
        getSelectedItemIndex,
    });

    const form = useForm<BrandProfileRef>({
        fields: [primaryFonts, secondaryFonts, additionalFonts],
        apiCall: async () => {
            try {
                // Get primary, secondary and additional fonts
                const readyPrimaryFonts = primaryFonts.value.map((font) => `${BrandProfileFontPrefix.PRIMARY}${font}`);
                const readySecondaryFonts = secondaryFonts.value.map(
                    (font) => `${BrandProfileFontPrefix.SECONDARY}${font}`
                );
                const readyAdditionalFonts = additionalFonts.value;
                const fonts = [...readyPrimaryFonts, ...readySecondaryFonts, ...readyAdditionalFonts];

                // Update brand profile fonts
                const brandProfileRef = await updateBrandProfileInformation({
                    id: data!.brandProfileId,
                    input: {
                        fonts,
                    },
                });

                // Update brand profile fonts state
                brandProfileApi.update({ fonts });

                return { response: brandProfileRef };
            } catch (e) {
                throw e;
            }
        },
        onSuccess: () => {
            closeModal?.();
        },
        onFailure: (error) => {
            // TODO: handle error
            console.error(error);
        },
    });

    if (!data) {
        closeModal?.();
        return null;
    }

    const renderFontsMultiSelect = (fonts: ReturnValue<string>, label: string, placeholder: string) => {
        const handleItemSelect = (item: string) => {
            const selectedItemIndex = getSelectedItemIndex(item, fonts.value);

            if (selectedItemIndex === -1) {
                fonts.handleSetValue([...fonts.value, item]);

                // update popularFonts array
                // check if item already exist in popularFonts array
                if (popularFonts.indexOf(item) === -1) {
                    // add item which do not exist in popularFonts
                    fonts.handleSetValue([...fonts.value, item]);
                }
            } else {
                fonts.handleUnselectItem(item);
            }
        };

        const handleCreateNewItem = (query: string) => {
            const items = query.split(',');
            return items.map((item) => item.trim());
        };

        const renderTag = (item: string) => {
            return item;
        };

        const renderItem = (item: string, { handleClick }: ItemRendererProps) => {
            return (
                <MenuItem
                    key={item}
                    text={item}
                    active={getSelectedItemIndex(item, fonts.value) !== -1}
                    onClick={handleClick}
                />
            );
        };

        const renderNewItem = (query: string, active: boolean, handleClick: React.MouseEventHandler<HTMLElement>) => {
            return <MenuItem icon="add" text={query} active={active} onClick={handleClick} />;
        };

        const singleItemFilter: ItemPredicate<string> = (query, item, _index, exactMatch) => {
            const normalizedTitle = item.toLowerCase();
            const normalizedQuery = query.toLowerCase();

            if (exactMatch) {
                return normalizedTitle === normalizedQuery;
            } else {
                // check if "normalizedQuery" is substring of "normalizedTitle" string, if not, then -1 will be returned
                return normalizedTitle.indexOf(normalizedQuery) >= 0;
            }
        };

        return (
            <FormGroup label={label}>
                <MultiSelect<string>
                    resetOnSelect
                    placeholder={placeholder}
                    items={popularFonts}
                    selectedItems={fonts.value}
                    createNewItemFromQuery={handleCreateNewItem}
                    createNewItemRenderer={renderNewItem}
                    tagRenderer={renderTag}
                    itemRenderer={renderItem}
                    onItemSelect={handleItemSelect}
                    itemPredicate={singleItemFilter}
                    onRemove={fonts.handleUnselectItem}
                    tagInputProps={{
                        large: false,
                    }}
                    menuProps={{
                        className: 'custom-thin-scroll',
                    }}
                    popoverProps={{
                        matchTargetWidth: true,
                    }}
                />
            </FormGroup>
        );
    };

    return (
        <Overlay isOpen onClose={closeModal}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Update Fonts</Heading>
                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <Divider className="mb-2" />

                <form onSubmit={form.handleFormSubmit}>
                    {renderFontsMultiSelect(primaryFonts, 'Primary fonts', 'Select or add new primary font')}
                    {renderFontsMultiSelect(secondaryFonts, 'Secondary fonts', 'Select or add new secondary font')}
                    {renderFontsMultiSelect(additionalFonts, 'Additional fonts', 'Select or add new additional font')}

                    <Flex className="mt-2" justify="flex-end">
                        <Button className="mr-1" outlined onClick={closeModal}>
                            Cancel
                        </Button>
                        <Button
                            disabled={form.hasFieldErrors}
                            loading={form.isSubmitting}
                            type="submit"
                            intent={Intent.PRIMARY}
                        >
                            Update
                        </Button>
                    </Flex>
                </form>
            </Card>
        </Overlay>
    );
};

export default UpdateBrandProfileFontsModal;
