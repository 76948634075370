import { Alignment, Breadcrumbs, Button, NavbarDivider, NavbarGroup, NavbarProps, Tooltip } from '@blueprintjs/core';
import { $authorizedUser, $pageBreadcrumbs } from '@containers/store/states';
import { useStore } from 'effector-react';
import React from 'react';
import { Navbar as StyledNavbar, UserInfoBlock } from './styled';
import DevText from '@components/Text';
import Avatar from '@components/Avatar';
import { imageHashPreview } from '@data/consts';
import { ImageHashPreviewSize } from 'dy-frontend-shared/lib/data/valueObjects/ImageHashPreview/enums';
import { useLogout } from '@app/hooks';

type Props = NavbarProps;

const Navbar: React.FC<Props> = (props) => {
    const logout = useLogout();

    const me = useStore($authorizedUser);
    const pageBreadcrumbs = useStore($pageBreadcrumbs);

    if (!me) {
        return null;
    }

    const renderAvatar = () => {
        // Get avatar src
        let avatarSrc: string | null = null;
        if (me?.user.image_hash) {
            avatarSrc = imageHashPreview.userImage(me.user.image_hash, ImageHashPreviewSize.SM);
        }
        return <Avatar alt={me.user.first_name} src={avatarSrc} />;
    };

    const renderPageBreadcrumbs = () => {
        if (!pageBreadcrumbs) {
            // No breadcrumbs for current page
            return null;
        }

        return (
            <NavbarGroup>
                <Breadcrumbs items={pageBreadcrumbs} />
            </NavbarGroup>
        );
    };

    return (
        <StyledNavbar {...props}>
            {/* <NavbarGroup>
                <Switch checked={isDevModeEnabled} onChange={() => toggleDevModeFlag()} />
            </NavbarGroup> */}

            {renderPageBreadcrumbs()}

            <NavbarGroup align={Alignment.RIGHT}>
                <UserInfoBlock className="mr-1">
                    <div className="mr-2">
                        <DevText align="end">
                            {me.user.first_name} {me.user.last_name}
                        </DevText>
                        <DevText muted align="end">
                            {me.user.email}
                        </DevText>
                    </div>
                    {renderAvatar()}
                </UserInfoBlock>

                <NavbarDivider />

                <Tooltip content="Logout">
                    <Button minimal icon="power" onClick={logout} />
                </Tooltip>
            </NavbarGroup>
        </StyledNavbar>
    );
};

export default Navbar;
