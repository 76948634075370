import AuthRoleResource from 'dy-frontend-http-repository/lib/modules/User/resources/AuthRoleResource';
import React, { HTMLAttributes, useEffect, useState } from 'react';
import { UserType } from 'dy-frontend-http-repository/lib/data/enums';
import { useStore } from 'effector-react';
import { $assignableAuthRoles, $userAuthRoles } from './store/states';
import { attachUserAuthRole, fetchAssignableAuthRoles, fetchUserAuthRoles, removeUserAuthRole } from './store/effects';
import Flex from '@components/Flex';
import { Button, Card, Classes, Colors, InputGroup, Intent, Label, Spinner, Tag, Tooltip } from '@blueprintjs/core';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import { resetAssignableAuthRoles, resetUserAuthRoles } from './store/events';
import ConfirmationPopover from '@components/ConfirmationPopover';
import { userAuthRolesApi } from '@containers/components/UserAuthRoles/store/apis';
import RouterLink from '@components/RouterLink';
import { Endpoints } from '@data/consts';
import { openModal } from '@app/containers/modals/store/events';
import AddUserAuthRoleModal, { AddUserAuthRoleModalProps } from './modals/AddUserAuthRoleModal';
import { AuthRoleListItemResource } from 'dy-frontend-http-repository/lib/modules/AuthRole/resources';

export interface UserAuthRolesProps {
    userId: ID;
    userType: UserType;
}

type Props = HTMLAttributes<HTMLDivElement> & UserAuthRolesProps;

const UserAuthRoles: React.FC<Props> = ({ userId, userType, ...props }) => {
    const userAuthRoles = useStore($userAuthRoles);
    const assignableAuthRoles = useStore($assignableAuthRoles);

    const [removingId, setRemovingId] = useState<ID | null>(null);

    useEffect(() => {
        // Fetch auth roles
        fetchUserAuthRoles(userId).catch((e) => {
            // TODO: handle error
            console.error(e);
        });

        // Fetch assignable auth roles
        fetchAssignableAuthRoles({ user_type: userType }).catch((e) => {
            // TODO: handle error
            console.error(e);
        });
    }, [userId]);

    useEffect(() => {
        return () => {
            resetUserAuthRoles();
            resetAssignableAuthRoles();
        };
    }, []);

    if (!userId) {
        return null;
    }

    if (!userAuthRoles || !assignableAuthRoles) {
        return (
            <Flex justify="center">
                <Spinner className="mt-3" />
            </Flex>
        );
    }

    const getAssignableAuthRoles = () => {
        let collectedAssignableRoles: AuthRoleListItemResource[] = [];
        if (userAuthRoles.items.length > 0) {
            // There are auth roles which were attached
            if (assignableAuthRoles.items.length > 0) {
                // There are auth roles which could still be attached

                // Get all attached auth roles
                const attachedAuthRoles = userAuthRoles.items.map((role) => role.auth_role.lookup_key);

                // Collect roles which are NOT attached yet
                collectedAssignableRoles = assignableAuthRoles.items.filter(
                    (role) => !attachedAuthRoles.includes(role.lookup_key)
                );
            }
        } else {
            // No auth roles attached yet
            if (assignableAuthRoles.items.length > 0) {
                // There are auth roles which could still be attached

                collectedAssignableRoles = assignableAuthRoles.items;
            }
        }

        return collectedAssignableRoles;
    };

    const handleAdd = async (collectedAssignableRoles: AuthRoleListItemResource[]) => {
        openModal<AddUserAuthRoleModalProps>({
            ModalComponent: AddUserAuthRoleModal,
            data: { userId, assignableRoles: collectedAssignableRoles },
        });
    };

    const handleRemove = async (roleId: ID) => {
        setRemovingId(roleId);

        try {
            await removeUserAuthRole({ userId: userId, roleId: roleId });
            userAuthRolesApi.removeAuthRole(roleId);
        } catch (e) {
            // TODO: handle error
            console.error(e);
        } finally {
            setRemovingId(null);
        }
    };

    const renderAddRoleButton = () => {
        // Collect assignable auth roles
        const collectedAssignableRoles = getAssignableAuthRoles();
        if (collectedAssignableRoles.length === 0) {
            return null;
        }

        return (
            <Button
                disabled={removingId !== null}
                icon="add"
                intent={Intent.PRIMARY}
                onClick={() => handleAdd(collectedAssignableRoles)}
            >
                Add role
            </Button>
        );
    };

    const renderHeader = () => {
        return (
            <Flex className="mb-2" align="center" justify="space-between">
                <Heading type="h3">Auth roles</Heading>
                <div>{renderAddRoleButton()}</div>
            </Flex>
        );
    };

    const renderItem = (item: AuthRoleResource) => {
        const renderRemoveButton = () => {
            // Render label if system role
            if (item.lookup_key !== null) {
                return (
                    <Tooltip content={item.lookup_key}>
                        <Tag minimal large intent={Intent.PRIMARY}>
                            System role
                        </Tag>
                    </Tooltip>
                );
            }

            return (
                <ConfirmationPopover
                    title="Remove this role from user?"
                    description="User will loose this roles and all permissions, associated with it. Proceed"
                    actions={[
                        <Button
                            intent={Intent.DANGER}
                            className={Classes.POPOVER_DISMISS}
                            onClick={() => handleRemove(item.id)}
                        >
                            Yes, remove role
                        </Button>,
                    ]}
                >
                    <Tooltip content="Remove role">
                        <Button
                            minimal
                            disabled={removingId !== null}
                            loading={removingId === item.id}
                            intent={Intent.DANGER}
                            icon="cross"
                        />
                    </Tooltip>
                </ConfirmationPopover>
            );
        };

        return (
            <Card key={`role-${item.id}`} className="mb-1">
                <Flex align="center" justify="space-between">
                    <RouterLink color={Colors.WHITE} to={Endpoints.AUTH_ROLE.replace(':authRoleId', item.id)}>
                        {item.title}
                    </RouterLink>
                    <div>{renderRemoveButton()}</div>
                </Flex>
            </Card>
        );
    };

    const renderList = () => {
        // Empty list
        if (userAuthRoles.items.length === 0) {
            return <DevText muted>User does not have any auth role, assigned to them</DevText>;
        }

        // Return list
        return userAuthRoles.items.map(({ auth_role }) => renderItem(auth_role));
    };

    return (
        <div {...props}>
            {renderHeader()}
            {renderList()}
        </div>
    );
};

export default UserAuthRoles;
