import React, { useEffect, useState } from 'react';
import { ModalProps } from '@modals/types';
import { TaskCategoryGroupResource } from 'dy-frontend-http-repository/lib/modules/Plan/resources';
import Overlay from '@components/Overlay';
import { Button, Card, Colors, Divider, Elevation, FormGroup, HTMLSelect, Intent } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import Circle from '@components/Circle';
import { fetchTaskCategoryGroups, updatePlanTaskCategoryGroupAttachmentList } from '@pages/Plan/store/effects';
import { planApi } from '@pages/Plan/store/apis';
import { useStore } from 'effector-react';
import { $taskCategoryGroups } from '../../store/states';

export interface SetPlanTaskCategoryGroupListModalProps {
    planId: ID;
    initialAttachmentList: TaskCategoryGroupResource[];
}

type Props = ModalProps<SetPlanTaskCategoryGroupListModalProps>;

const SetPlanTaskCategoryGroupListModal: React.FC<Props> = ({ closeModal, data }) => {
    const taskCategoryGroups = useStore($taskCategoryGroups);

    const [isProcessing, setIsProcessing] = useState(false);
    const [attachments, setAttachments] = useState<TaskCategoryGroupResource[] | null>(null);
    const [selectedTaskCategoryGroupId, setSelectedTaskCategoryGroupId] = useState<ID | null>(null);

    useEffect(() => {
        fetchTaskCategoryGroups().catch((e) => {
            // TODO: handle error
            console.error(e);
        });
    }, []);

    useEffect(() => {
        if (!data) {
            handleModalClose();
            return;
        }

        // Skip if already initialized
        if (attachments !== null) {
            return;
        }

        setAttachments(data.initialAttachmentList);
    }, [data]);

    const handleModalClose = () => {
        if (isProcessing) {
            return;
        }

        closeModal?.();
    };

    if (!data) {
        handleModalClose();
        return null;
    }

    const handleSubmit = () => {
        if (attachments === null) {
            return;
        }

        const nextAttachmentList: ID[] = attachments.map((i) => i.id);

        // Send data
        setIsProcessing(true);
        updatePlanTaskCategoryGroupAttachmentList({ planId: data.planId, input: nextAttachmentList })
            .then(() => {
                // Touch API
                planApi.updateTaskCategoryGroupList(attachments);

                // Close modal
                closeModal?.();
            })
            .catch(console.error)
            .finally(() => setIsProcessing(false));
    };

    const handleRemoveElement = (index: number) => {
        if (!attachments) {
            return;
        }

        setAttachments(attachments.filter((_, i) => index !== i));
    };

    const handleAddAttachment = () => {
        if (!attachments) {
            return;
        }

        if (!taskCategoryGroups) {
            return;
        }

        const foundTaskCategoryGroup = taskCategoryGroups.items.find(
            (group) => group.id === selectedTaskCategoryGroupId
        );

        if (!foundTaskCategoryGroup) {
            return;
        }

        setAttachments([
            ...attachments,
            {
                id: foundTaskCategoryGroup.id,
                archived_at: foundTaskCategoryGroup.archived_at,
                color: foundTaskCategoryGroup.color,
                title: foundTaskCategoryGroup.title,
            },
        ]);
    };

    const renderAttachmentItem = (index: number, item: TaskCategoryGroupResource) => {
        return (
            <Card elevation={Elevation.TWO}>
                <Flex align="center" justify="space-between">
                    <Flex>
                        <Circle color={item.color !== '' ? item.color : Colors.GRAY2} className="mr-1" />
                        <DevText>{item.title}</DevText>
                    </Flex>
                    <Button disabled={isProcessing} icon="cross" onClick={() => handleRemoveElement(index)} />
                </Flex>
            </Card>
        );
    };

    const renderAttachmentList = () => {
        if (attachments === null) {
            return;
        }

        if (attachments.length === 0) {
            return <DevText muted>No request category groups added</DevText>;
        }

        return attachments.map((i, index) => (
            <div key={`attachment-group-${i.id}`} className="mb-1">
                {renderAttachmentItem(index, i)}
            </div>
        ));
    };

    const renderTaskCategoryGroupPicker = () => {
        if (!attachments) {
            return null;
        }

        if (!taskCategoryGroups) {
            return null;
        }

        const availableTaskCategoryGroups = taskCategoryGroups.items.filter(
            (group) => !attachments.find((a) => a.id === group.id)
        );

        return (
            <FormGroup label="Select task category group to add">
                <Flex>
                    <HTMLSelect
                        fill
                        disabled={!taskCategoryGroups || availableTaskCategoryGroups.length === 0}
                        className="mr-1"
                        value={String(selectedTaskCategoryGroupId)}
                        onChange={(e) => setSelectedTaskCategoryGroupId(e.target.value)}
                    >
                        <option value={undefined}>Select request category group</option>
                        {availableTaskCategoryGroups.map((group) => (
                            <option key={`qt-${group.id}`} value={group.id}>
                                {group.title}
                            </option>
                        ))}
                    </HTMLSelect>

                    <Button
                        disabled={selectedTaskCategoryGroupId === null}
                        onClick={() => {
                            if (selectedTaskCategoryGroupId === null) {
                                return;
                            }

                            handleAddAttachment();
                            setSelectedTaskCategoryGroupId(null);
                        }}
                    >
                        Add
                    </Button>
                </Flex>
            </FormGroup>
        );
    };

    return (
        <Overlay isOpen onClose={handleModalClose}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Request category groups, included in plan</Heading>
                    <Button minimal icon="cross" onClick={closeModal} disabled={isProcessing} />
                </Flex>

                <div className="mb-2">{renderTaskCategoryGroupPicker()}</div>

                <Divider className="mb-2" />

                <div className="mb-2">{renderAttachmentList()}</div>

                <Divider className="mb-2" />

                <Flex align="center" justify="space-between">
                    <DevText muted>Save info about groups?</DevText>
                    <div>
                        <Button minimal onClick={handleModalClose} className="mr-1" disabled={isProcessing}>
                            No, cancel
                        </Button>
                        <Button intent={Intent.PRIMARY} loading={isProcessing} onClick={handleSubmit}>
                            Yes, save data
                        </Button>
                    </div>
                </Flex>
            </Card>
        </Overlay>
    );
};

export default SetPlanTaskCategoryGroupListModal;
