import { setPointMarker } from '../../store/events';

const useSetPointMarker = () => {
    const handleSetPointMarker = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const container = event.currentTarget.getBoundingClientRect();
        const [posX, posY] = [(event.clientX - container.left) / container.width, (event.clientY - container.top) / container.height];
        setPointMarker([posX, posY]);
    };

    return { handleSetPointMarker };
};

export default useSetPointMarker;

