import { useForm, useTextFormField } from '@app/hooks';
import {
    getStringMaxLengthValidator,
    getStringMinLengthValidator,
    getStringRequiredValidator,
} from '@app/hooks/validation/functions';
import { Button, Checkbox, Divider, Intent } from '@blueprintjs/core';
import Flex from '@components/Flex';
import InputFormField from '@components/InputFormField';
import { taskApi, taskStateTransitionLogApi } from '@containers/pages/Task/store/apis';
import { $authorizedUser } from '@containers/store/states';
import { TaskState } from 'dy-frontend-http-repository/lib/data/enums';
import { TaskPublishOutputQueue } from 'dy-frontend-http-repository/lib/modules/TaskPublish/enums';
import { useStore } from 'effector-react';
import moment from 'moment';
import React, { useState } from 'react';
import { PublishTaskStep } from '../../enums';
import { useTaskPublish } from '../../hooks';
import { changeStep } from '../../store/events';
import { CommonStepProps } from '../../types';
import Heading from '@components/Heading';

export type Props = CommonStepProps;

const manualTaskPublishCommentValidators = [
    getStringRequiredValidator(),
    getStringMinLengthValidator({ minStringLength: 5 }),
    getStringMaxLengthValidator({ maxStringLength: 100 }),
];

const PublishTaskViaManualStep: React.FC<Props> = ({ closeModal, data }) => {
    const { handlePublishTaskViaManual } = useTaskPublish({
        taskId: data.taskId,
        shouldCheckTaskPublishChoices: false,
    });

    const me = useStore($authorizedUser);

    const [publishOutputQueue, setPublishOutputQueue] = useState<TaskPublishOutputQueue>(TaskPublishOutputQueue.ACTIVE);

    const manualTaskPublishComment = useTextFormField({
        id: 'manual-task-publish-comment',
        validators: manualTaskPublishCommentValidators,
        initialValue: '',
    });

    const form = useForm({
        fields: [manualTaskPublishComment],
        apiCall: async () => {
            try {
                const momentNowUtc = moment().utc().format();
                const response = await handlePublishTaskViaManual({
                    comment: manualTaskPublishComment.value,
                    output_queue: publishOutputQueue,
                    task_id: data.taskId,
                });
                taskApi.transitionState({ state: TaskState.PUBLISHED });
                taskStateTransitionLogApi.update([
                    {
                        comment: '',
                        applied_at: momentNowUtc,
                        is_forced: false,
                        state: TaskState.PUBLISHED,
                        user: {
                            first_name: me!.user.first_name,
                            id: me!.user.id,
                            last_name: me!.user.last_name,
                            image_hash: me!.user.image_hash,
                            type: me!.user.type,
                        },
                    },
                ]);

                return { response };
            } catch (e) {
                throw e;
            }
        },
        onSuccess: () => {
            closeModal?.();
        },
        onFailure: (error) => {
            // TODO: handle error
            console.error(error);
        },
    });

    if (!me) {
        closeModal?.();

        return null;
    }

    return (
        <div>
            <Flex className="mb-2" align="center" justify="space-between">
                <Heading type="h4">Manual publish</Heading>
                <Button minimal icon="cross" onClick={closeModal} />
            </Flex>

            <Divider className="mb-2" />

            <form onSubmit={form.handleFormSubmit}>
                {/* Comment*/}
                <InputFormField
                    field={manualTaskPublishComment}
                    formGroupProps={{ label: 'Publish comment' }}
                    inputProps={{ placeholder: 'Enter publish comment' }}
                />

                {/* Publish output queue */}
                <Checkbox
                    checked={publishOutputQueue === TaskPublishOutputQueue.BACKLOG}
                    label='Publish to "Backlog"'
                    onChange={() =>
                        setPublishOutputQueue((prevValue) =>
                            prevValue === TaskPublishOutputQueue.ACTIVE
                                ? TaskPublishOutputQueue.BACKLOG
                                : TaskPublishOutputQueue.ACTIVE
                        )
                    }
                />

                <Flex justify="flex-end">
                    <Button className="mr-1" outlined onClick={() => changeStep(PublishTaskStep.PUBLISH_METHOD_SELECT)}>
                        Select other method
                    </Button>

                    <Button
                        disabled={form.hasFieldErrors}
                        loading={form.isSubmitting}
                        type="submit"
                        intent={Intent.PRIMARY}
                    >
                        Publish
                    </Button>
                </Flex>
            </form>
        </div>
    );
};

export default PublishTaskViaManualStep;
