import { $permissions } from '@app/containers/store/states';
import { Button, Tag } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import { openModal } from '@containers/modals/store/events';
import { $brandProfile } from '@pages/BrandProfile/store/states';
import { TextFormatUtils } from 'dy-frontend-shared/lib/utils';
import { useStore } from 'effector-react';
import React, { HTMLAttributes, useMemo } from 'react';
import UpdateBrandProfileFontsModal, {
    UpdateBrandProfileFontsModalProps,
} from '../../modals/UpdateBrandProfileFontsModal';
import { StyledCard } from './styled';
import { BrandProfilePermission } from 'dy-frontend-permissions/lib/permission';
import { BrandProfileFontPrefix } from 'dy-frontend-shared/lib/data/enums';

type Props = HTMLAttributes<HTMLDivElement>;

const Fonts: React.FC<Props> = (props) => {
    const permissions = useStore($permissions);

    const brandProfile = useStore($brandProfile);

    const fonts = useMemo<{ [type in string]: string[] }>(() => {
        if (!brandProfile || brandProfile.fonts.length === 0) {
            // Brand profile is NOT fetched yet or there are not fonts added yet
            return {
                [BrandProfileFontPrefix.PRIMARY]: [],
                [BrandProfileFontPrefix.SECONDARY]: [],
                additional: [],
            };
        }

        // Get primary & secondary & additional fonts
        const primaryFonts: string[] = [];
        const secondaryFonts: string[] = [];
        const additionalFonts: string[] = [];
        for (const font of brandProfile.fonts) {
            if (font.startsWith(BrandProfileFontPrefix.PRIMARY)) {
                // Primary font
                const splittedFont = font.split(BrandProfileFontPrefix.PRIMARY)[1];
                if (splittedFont) {
                    primaryFonts.push(splittedFont);
                }
            } else if (font.startsWith(BrandProfileFontPrefix.SECONDARY)) {
                // Secondary font
                const splittedFont = font.split(BrandProfileFontPrefix.SECONDARY)[1];
                if (splittedFont) {
                    secondaryFonts.push(splittedFont);
                }
            } else {
                // Additional font
                additionalFonts.push(font);
            }
        }

        return {
            [BrandProfileFontPrefix.PRIMARY]: primaryFonts,
            [BrandProfileFontPrefix.SECONDARY]: secondaryFonts,
            additional: additionalFonts,
        };
    }, [brandProfile?.fonts]);

    if (!brandProfile) {
        return null;
    }

    const renderFonts = (title: string, fonts?: string[]) => {
        if (!fonts || fonts.length === 0) {
            return (
                <div>
                    <DevText muted className="mb-small">
                        {title}
                    </DevText>
                    <DevText muted>No data</DevText>
                </div>
            );
        }

        return (
            <div>
                <DevText muted className="mb-small">
                    {title}
                </DevText>
                <Flex gap="8px" direction="row" flexWrap="wrap">
                    {fonts.map((font) => (
                        <Tag key={font}>{TextFormatUtils.capitalize(font)}</Tag>
                    ))}
                </Flex>
            </div>
        );
    };

    const renderUpdateFontsButton = () => {
        const isAllowed = permissions.isRoot.brandProfile || permissions.has(BrandProfilePermission.UPDATE);
        if (!isAllowed) {
            return null;
        }

        return (
            <Button
                icon="edit"
                onClick={() =>
                    openModal<UpdateBrandProfileFontsModalProps>({
                        ModalComponent: UpdateBrandProfileFontsModal,
                        data: {
                            brandProfileId: brandProfile.id,
                            fonts: brandProfile.fonts,
                        },
                    })
                }
            >
                Edit
            </Button>
        );
    };

    return (
        <StyledCard {...props}>
            <Flex className="mb-2" align="center" justify="space-between">
                <Heading type="h4">Fonts</Heading>
                {renderUpdateFontsButton()}
            </Flex>

            <div className="mb-2">{renderFonts('Primary fonts', fonts[BrandProfileFontPrefix.PRIMARY])}</div>
            <div className="mb-2">{renderFonts('Secondary fonts', fonts[BrandProfileFontPrefix.SECONDARY])}</div>
            {renderFonts('Secondary fonts', fonts['additional'])}
        </StyledCard>
    );
};

export default Fonts;
