import React, { HTMLAttributes } from 'react';
import { Endpoints, imageHashPreview } from '@app/data/consts';
import Flex from '@app/components/Flex';
import Heading from '@app/components/Heading';
import { Card, Classes, Colors, Icon, IconName, Tooltip } from '@blueprintjs/core';
import {
    ScoreSummaryCountResource,
    TaskClientFeedbackSummaryResource,
    TaskParticipationResource,
    TeamResource,
    TaskClientFeedbackResource,
} from 'dy-frontend-http-repository/lib/modules/TaskClientFeedback/resources';
import DevText from '@app/components/Text';
import RatioTile from '@app/components/RatioTile';
import { RatioIndexData } from '@app/components/RatioTile/types';
import Grid from '@app/components/Grid';
import TableCell from '@app/components/TableCell';
import Table from '@app/components/Table';
import NonIdealState from '@app/components/NonIdealState';
import RouterLink from '@app/components/RouterLink';
import moment from 'moment';
import { taskFeedbackInformation } from 'dy-frontend-shared/lib/data/consts';
import { TaskFeedbackCriteria } from 'dy-frontend-shared/lib/data/enums';
import { ImageHashPreviewSize } from 'dy-frontend-shared/lib/data/valueObjects/ImageHashPreview/enums';
import AvatarStack from '@app/components/AvatarStack';
import Circle from '@app/components/Circle';
import Box from '@app/components/Box';

export interface TaskClientFeedbackProps {
    loading?: boolean;
    loadingSummary?: boolean;
    taskClientFeedback: TaskClientFeedbackResource[];
    taskClientFeedbackSummary: TaskClientFeedbackSummaryResource;
}
export type Props = HTMLAttributes<HTMLDivElement> & TaskClientFeedbackProps;

const TaskClientFeedback: React.FC<Props> = ({
    loading,
    loadingSummary,
    taskClientFeedback,
    taskClientFeedbackSummary,
    ...props
}) => {
    const renderTaskClientFeedbackSummary = () => {
        const getRatioTileConfig = (
            scoreSummaryCount: ScoreSummaryCountResource
        ): { [key in string]: RatioIndexData } => {
            // Get positive ratio index data
            const positiveRatioIndexData: RatioIndexData = {
                color: Colors.GREEN3,
                label: 'Positive',
                value: scoreSummaryCount.positive,
            };

            // Get neutral ratio index data
            const neutralRatioIndexData: RatioIndexData = {
                color: Colors.GRAY5,
                label: 'Neutral',
                value: scoreSummaryCount.neutral,
            };

            // Get negative ratio index data
            const negativeRatioIndexData: RatioIndexData = {
                color: Colors.RED3,
                label: 'Negative',
                value: scoreSummaryCount.negative,
            };

            // Get absent ratio index data
            const absentRatioIndexData: RatioIndexData = {
                color: Colors.GRAY1,
                label: 'Absent',
                value: scoreSummaryCount.absent,
            };

            return {
                positive: positiveRatioIndexData,
                neutral: neutralRatioIndexData,
                negative: negativeRatioIndexData,
                absent: absentRatioIndexData,
            };
        };

        const renderScoreIndex = (title: string, count: number) => {
            return (
                <Flex direction="column">
                    <Heading className="mb-small" type="h3">
                        {count}
                    </Heading>
                    <DevText small>{title}</DevText>
                </Flex>
            );
        };

        const renderScoreSummaryCountCard = (
            title: string,
            icon: IconName,
            scoreSummaryCount: ScoreSummaryCountResource
        ) => {
            // Get total score
            const totalScore =
                scoreSummaryCount.positive +
                scoreSummaryCount.neutral +
                scoreSummaryCount.negative +
                scoreSummaryCount.absent;

            // TODO: move ".toFixed(2).replace(/[.,]0+$/, '')" to utils, this code works like that "5.00" will show as "5", but "25.256457457", will be "25.25", same for "65.55", it'll be "65.55"
            // Get positive feedback percentage
            let positiveFeedbackPercentage = (scoreSummaryCount.positive / totalScore) * 100;
            if (isNaN(positiveFeedbackPercentage)) {
                positiveFeedbackPercentage = 0;
            }

            // Get skeleton class name
            const skeletonClassName = loadingSummary ? Classes.SKELETON : '';

            return (
                <Card>
                    <Flex className="mb-2" direction="row" justify="space-between">
                        <DevText muted>{title}</DevText>
                        <DevText muted>
                            <Icon icon={icon} />
                        </DevText>
                    </Flex>

                    <Heading className={`mb-1 ${skeletonClassName}`} type="h1">
                        {Math.round(positiveFeedbackPercentage)}%
                    </Heading>

                    <RatioTile className={`mb-1 ${skeletonClassName}`} config={getRatioTileConfig(scoreSummaryCount)} />

                    <Flex direction="row">
                        <div className={`mr-3 ${skeletonClassName}`}>
                            {renderScoreIndex('Positive', scoreSummaryCount.positive)}
                        </div>

                        <div className={skeletonClassName}>
                            {renderScoreIndex('Negative', scoreSummaryCount.negative)}
                        </div>
                    </Flex>
                </Card>
            );
        };

        return (
            <Grid container>
                <Grid className="mb-2" lg={3} sm={6} xs={12}>
                    {renderScoreSummaryCountCard('Creativity', 'clean', taskClientFeedbackSummary.score_creativity)}
                </Grid>

                <Grid className="mb-2" lg={3} sm={6} xs={12}>
                    {renderScoreSummaryCountCard('Quality', 'star', taskClientFeedbackSummary.score_quality)}
                </Grid>

                <Grid className="mb-2" lg={3} sm={6} xs={12}>
                    {renderScoreSummaryCountCard('Speed', 'dashboard', taskClientFeedbackSummary.score_speed)}
                </Grid>

                <Grid className="mb-2" lg={3} sm={6} xs={12}>
                    {renderScoreSummaryCountCard(
                        'Communication',
                        'chat',
                        taskClientFeedbackSummary.score_communication
                    )}
                </Grid>
            </Grid>
        );
    };

    const renderTaskClientFeedback = () => {
        if (taskClientFeedback.length === 0) {
            return (
                <NonIdealState
                    icon={<Icon className="mb-2" icon="search" size={70} />}
                    title={
                        <Heading type="h4" className="mb-1">
                            No client feedback was found
                        </Heading>
                    }
                />
            );
        }

        const renderTableHeader = () => {
            return (
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Added at</th>
                        <th>Client</th>
                        <th>
                            <Icon className="mr-small" icon="clean" />
                            Creativity
                        </th>
                        <th>
                            <Icon className="mr-small" icon="star" />
                            Quality
                        </th>
                        <th>
                            <Icon className="mr-small" icon="dashboard" />
                            Speed
                        </th>
                        <th>
                            <Icon className="mr-small" icon="chat" />
                            Communication
                        </th>
                        <th>Participants</th>
                    </tr>
                </thead>
            );
        };

        const renderTableBody = () => {
            const renderScore = (feedbackCriteria: TaskFeedbackCriteria, value: number) => {
                const info = taskFeedbackInformation[feedbackCriteria];

                if (info.positiveFeedbackScore === value) {
                    // Positive
                    return <DevText color={Colors.GREEN3}>Positive</DevText>;
                }

                if (info.negativeFeedbackScore === value) {
                    // Negative
                    return <DevText color={Colors.RED3}>Negative</DevText>;
                }

                return <DevText muted>No data</DevText>;
            };

            const renderParticipantTeams = (participants: TaskParticipationResource[]) => {
                if (participants.length === 0) {
                    // Participant user is NOT part of any team
                    return null;
                }

                // Get team map
                const teamMap: { [teamId in ID]: TeamResource } = {};
                participants.forEach(({ user: { team_participation } }) =>
                    team_participation.forEach((p) => (teamMap[p.team.id] = p.team))
                );

                // Get teams
                const teams = Object.values(teamMap);

                return teams.map((team) => (
                    <Flex direction="row" align="flex-start" key={team.id}>
                        <Circle className="mt-small mr-small" color={team.color} />
                        <RouterLink to={Endpoints.TEAM_MEMBERS.replace(':teamId', team.id)}>{team.title}</RouterLink>
                    </Flex>
                ));
            };

            const renderTaskParticipants = (participants: TaskParticipationResource[]) => {
                if (participants.length === 0) {
                    return <DevText muted>No participants</DevText>;
                }

                // Create AvatarStack avatars property
                const participantAvatarStackData = participants.map((p) => ({
                    id: p.user.id,
                    name: `${p.user.first_name} ${p.user.last_name}`,
                    src: p.user.image_hash
                        ? imageHashPreview.userImage(p.user.image_hash, ImageHashPreviewSize.XS)
                        : null,
                }));

                return (
                    <AvatarStack
                        withCounter
                        tight={false}
                        className="mr-2"
                        avatars={participantAvatarStackData}
                        avatarSize={40}
                    />
                );
            };

            const renderTaskFeedbackCommentIcon = (comment: string) => {
                if (comment.trim().length === 0) {
                    return null;
                }

                return (
                    <Tooltip
                        content={<Box maxWidth="300px">{comment}</Box>}
                        renderTarget={(props) => (
                            <Box cursor="pointer" style={{ display: 'inline-block' }} {...props}>
                                <DevText muted inline>
                                    <Icon icon="chat" />
                                </DevText>
                            </Box>
                        )}
                    />
                );
            };

            const renderRow = (taskClientFeedback: TaskClientFeedbackResource) => {
                return (
                    <tr key={taskClientFeedback.id}>
                        <TableCell>
                            <RouterLink
                                className="mr-1"
                                to={Endpoints.TASK_INFORMATION.replace(':taskId', taskClientFeedback.task.id)}
                            >
                                {taskClientFeedback.task.title}
                            </RouterLink>
                            {renderTaskFeedbackCommentIcon(taskClientFeedback.comment)}
                        </TableCell>
                        <TableCell>
                            <DevText muted>{moment(taskClientFeedback.updated_at).format('D MMM YYYY')}</DevText>
                        </TableCell>
                        <TableCell>
                            <RouterLink
                                to={Endpoints.CLIENT_TASKS.replace(':clientId', taskClientFeedback.author_user.id)}
                            >
                                {taskClientFeedback.author_user.first_name} {taskClientFeedback.author_user.last_name}
                            </RouterLink>

                            {taskClientFeedback.author_user.company_name.length > 0 && (
                                <DevText muted>{taskClientFeedback.author_user.company_name}</DevText>
                            )}
                        </TableCell>
                        <TableCell>
                            {renderScore(TaskFeedbackCriteria.CREATIVITY, taskClientFeedback.score_creativity)}
                        </TableCell>
                        <TableCell>
                            {renderScore(TaskFeedbackCriteria.QUALITY, taskClientFeedback.score_quality)}
                        </TableCell>
                        <TableCell>{renderScore(TaskFeedbackCriteria.SPEED, taskClientFeedback.score_speed)}</TableCell>
                        <TableCell>
                            {renderScore(TaskFeedbackCriteria.COMMUNICATION, taskClientFeedback.score_communication)}
                        </TableCell>
                        <TableCell>
                            <div className="mb-1">{renderParticipantTeams(taskClientFeedback.task.participants)}</div>
                            {renderTaskParticipants(taskClientFeedback.task.participants)}
                        </TableCell>
                    </tr>
                );
            };

            return <tbody>{taskClientFeedback.map(renderRow)}</tbody>;
        };

        return (
            <Table striped loading={loading}>
                {renderTableHeader()}
                {renderTableBody()}
            </Table>
        );
    };

    return (
        <div {...props}>
            {/* Task client feedback summary */}
            <div className="mb-1">{renderTaskClientFeedbackSummary()}</div>
            {/* Task client feedback */}
            {renderTaskClientFeedback()}
        </div>
    );
};

export default TaskClientFeedback;
