import { useCustomMultiSelectFormField, useForm } from '@app/hooks';
import { Button, Card, Divider, FormGroup, Intent, MenuItem, Spinner } from '@blueprintjs/core';
import { ItemPredicate, ItemRendererProps, MultiSelect } from '@blueprintjs/select';
import Flex from '@components/Flex';
import Grid from '@components/Grid';
import Heading from '@components/Heading';
import Overlay from '@components/Overlay';
import { ModalProps } from '@modals/types';
import { TaskCategoryRef } from 'dy-frontend-http-repository/lib/modules/TaskCategory/refs';
import { TaskCategoryGroupListItemResource } from 'dy-frontend-http-repository/lib/modules/TaskCategoryGroup/resources';
import { useStore } from 'effector-react';
import React, { useEffect } from 'react';
import { taskCategoryApi } from '../../store/apis';
import { fetchTaskCategoryGroups, updateAttachedTaskCategoryGroups } from '../../store/effects';
import { $taskCategoryGroups } from '../../store/states';

export interface UpdateTaskCategoryAttachedGroupsModalProps {
    taskCategoryId: ID;
    selectedTaskCategoryGroupIds: ID[];
}

type Props = ModalProps<UpdateTaskCategoryAttachedGroupsModalProps>;

const selectedTaskCategoryGroupsValidators = [];
const getSelectedItemIndex = (item: TaskCategoryGroupListItemResource, items: TaskCategoryGroupListItemResource[]) => {
    return items.findIndex((group) => group.id === item.id);
};
const UpdateTaskCategoryAttachedGroupsModal: React.FC<Props> = ({ closeModal, data }) => {
    const getInitializedTaskCategoryGroups = (groups: TaskCategoryGroupListItemResource[]) => {
        if (!data) {
            return [];
        }

        if (groups.length === 0) {
            return [];
        }

        return groups.filter((group) => data.selectedTaskCategoryGroupIds.indexOf(group.id) !== -1);
    };
    const taskCategoryGroups = useStore($taskCategoryGroups);

    const selectedTaskCategoryGroups = useCustomMultiSelectFormField<TaskCategoryGroupListItemResource>({
        id: 'form-selected-task-category-groups',
        validators: selectedTaskCategoryGroupsValidators,
        initialValue: taskCategoryGroups ? getInitializedTaskCategoryGroups(taskCategoryGroups.items) : [],
        getSelectedItemIndex,
    });

    const form = useForm<TaskCategoryRef>({
        fields: [selectedTaskCategoryGroups],
        apiCall: async () => {
            try {
                const taskCategoryRef = await updateAttachedTaskCategoryGroups({
                    taskCategoryId: data!.taskCategoryId,
                    input: { task_category_group_ids: selectedTaskCategoryGroups.value.map((group) => group.id) },
                });

                taskCategoryApi.updateGroups({ input: selectedTaskCategoryGroups.value });

                return { response: taskCategoryRef };
            } catch (e) {
                throw e;
            }
        },
        onSuccess: () => {
            closeModal?.();
        },
        onFailure: (error) => {
            // TODO: handle error
            console.error(error);
        },
    });

    useEffect(() => {
        if (!data || taskCategoryGroups !== null) {
            return;
        }

        fetchTaskCategoryGroups()
            .then((groups) => {
                selectedTaskCategoryGroups.handleSetValue(getInitializedTaskCategoryGroups(groups.items));
            })
            .catch((e) => {
                // TODO: handle error
                console.error(e);
            });

        // eslint-disable-next-line
    }, []);

    if (!data) {
        closeModal?.();
        return null;
    }

    const renderTaskCategoryGroupsMultiSelect = () => {
        if (!taskCategoryGroups) {
            return null;
        }

        const renderTag = (item: TaskCategoryGroupListItemResource) => {
            return item.title;
        };

        const renderItem = (item: TaskCategoryGroupListItemResource, { handleClick }: ItemRendererProps) => {
            return (
                <MenuItem
                    key={item.id}
                    text={item.title}
                    active={getSelectedItemIndex(item, selectedTaskCategoryGroups.value) !== -1}
                    onClick={handleClick}
                />
            );
        };

        const singleItemFilter: ItemPredicate<TaskCategoryGroupListItemResource> = (
            query,
            item,
            _index,
            exactMatch
        ) => {
            const normalizedTitle = item.title.toLowerCase();
            const normalizedQuery = query.toLowerCase();

            if (exactMatch) {
                return normalizedTitle === normalizedQuery;
            } else {
                // check if "normalizedQuery" is substring of "normalizedTitle" string, if not, then -1 will be returned
                return normalizedTitle.indexOf(normalizedQuery) >= 0;
            }
        };

        return (
            <MultiSelect<TaskCategoryGroupListItemResource>
                resetOnSelect
                placeholder="Select task category groups"
                items={taskCategoryGroups.items}
                selectedItems={selectedTaskCategoryGroups.value}
                tagRenderer={renderTag}
                itemRenderer={renderItem}
                itemPredicate={singleItemFilter}
                tagInputProps={{ large: false }}
                menuProps={{
                    className: 'custom-thin-scroll',
                }}
                popoverProps={{
                    matchTargetWidth: true,
                }}
                onItemSelect={selectedTaskCategoryGroups.handleChange}
                onRemove={selectedTaskCategoryGroups.handleUnselectItem}
            />
        );
    };

    const renderUpdateTaskCategoryAttachedGroupForm = () => {
        if (taskCategoryGroups === null) {
            return (
                <Flex justify="center">
                    <Spinner />
                </Flex>
            );
        }

        return (
            <form onSubmit={form.handleFormSubmit}>
                <Grid container className="mb-2">
                    <Grid lg={12}>
                        <FormGroup label="Category Groups">{renderTaskCategoryGroupsMultiSelect()}</FormGroup>
                    </Grid>
                </Grid>

                <Flex justify="flex-end">
                    <Button className="mr-1" outlined onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button
                        disabled={form.hasFieldErrors}
                        loading={form.isSubmitting}
                        type="submit"
                        intent={Intent.PRIMARY}
                    >
                        Update
                    </Button>
                </Flex>
            </form>
        );
    };

    return (
        <Overlay isOpen onClose={closeModal}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Update attached category groups</Heading>
                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <Divider className="mb-2" />

                {renderUpdateTaskCategoryAttachedGroupForm()}
            </Card>
        </Overlay>
    );
};

export default UpdateTaskCategoryAttachedGroupsModal;
