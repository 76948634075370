import React, { useEffect, useState } from 'react';
import { ModalProps } from '@modals/types';
import Overlay from '@components/Overlay';
import {
    Alignment,
    Button,
    Card,
    Elevation,
    FormGroup,
    Icon,
    Intent,
    MenuItem,
    Radio,
    RadioCard,
    RadioGroup,
    Spinner,
} from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import { TaskCategoryAddonResource } from 'dy-frontend-http-repository/lib/modules/TaskCategoryAddon/resources';
import {
    createTaskAddon,
    fetchTaskCategoryAddons,
    finalizeTaskAddonCheckout,
    initializeTaskAddonCheckout,
} from './store/effects';
import { taskCategoryAddonsApi } from './store/apis';
import { resetTaskCategoryAddons } from './store/events';
import { $taskCategoryAddons } from './store/states';
import { useStore } from 'effector-react';
import { $clientPaymentAccount, $task } from '@app/containers/pages/Task/store/states';
import NonIdealState from '@app/components/NonIdealState';
import DevText from '@app/components/Text';
import { TaskAddonAssignMethod, TaskAddonPaymentAmountType } from './enums';
import InputFormField from '@app/components/InputFormField';
import { useCustomSelectFormField, useForm, useTextFormField } from '@app/hooks';
import { getStringNumericValueValidator, getStringRequiredValidator } from '@app/hooks/validation/functions';
import { PriceUtils } from 'dy-frontend-shared/lib/utils';
import { ItemRendererProps } from '@blueprintjs/select';
import { PaymentMethodResource } from 'dy-frontend-http-repository/lib/modules/PaymentAccount/resources';
import Select from '@components/Select';
import { fetchClientPaymentAccount, fetchTaskAddons } from '@app/containers/pages/Task/store/effects';
import { InitializeAddonTaskCheckoutInput } from 'dy-frontend-http-repository/lib/modules/AddonTask/inputs';
import { FormField } from '@app/hooks/validation/types';
import { $permissions } from '@containers/store/states';
import { AddonTaskPermission } from 'dy-frontend-permissions/lib/permission';

const paymentUsdAmountValidators = [getStringRequiredValidator(), getStringNumericValueValidator()];
const paymentTaskPublishPaymentMethodIdValidators = [getStringRequiredValidator()];
const paymentTaskPublishStripeInvoiceIdValidators = [getStringRequiredValidator()];

export interface AssignTaskAddonModalProps {
    taskCategoryId: ID;
    selectedTaskCategoryAddonIds: ID[];
}

export type Props = ModalProps<AssignTaskAddonModalProps>;

const AssignTaskAddonModal: React.FC<Props> = ({ closeModal, data }) => {
    const permissions = useStore($permissions);

    const task = useStore($task);
    const taskCategoryAddons = useStore($taskCategoryAddons);
    const clientPaymentAccount = useStore($clientPaymentAccount);

    const [selectedTaskAddonAssignMethod, setSelectedTaskAddonAssignMethod] = useState<TaskAddonAssignMethod>();
    const [selectedTaskCategoryAddons, setSelectedTaskCategoryAddons] = useState<TaskCategoryAddonResource[]>([]);
    const [selectedTaskAddonPaymentAmountType, setSelectedTaskAddonPaymentAmountType] = useState<
        TaskAddonPaymentAmountType | undefined
    >(TaskAddonPaymentAmountType.TASK_ADDON);

    // Root permissions for addon task
    const isAddonTaskRootPermission = permissions.has(AddonTaskPermission.ROOT);

    const paymentUsdAmount = useTextFormField({
        id: 'payment-task-publish-usd-amount',
        validators: paymentUsdAmountValidators,
        initialValue: '',
    });

    const paymentPaymentMethodId = useCustomSelectFormField<ID | null>({
        id: 'payment-task-publish-stripe-payment-method-id',
        validators: paymentTaskPublishPaymentMethodIdValidators,
        initialValue: '',
        formatValue: (value) => value ?? '',
    });

    const paymentTaskPublishStripeInvoiceId = useTextFormField({
        id: 'payment-task-publish-stripe-invoice-id',
        validators: paymentTaskPublishStripeInvoiceIdValidators,
        initialValue: '',
    });

    const getFormFields = () => {
        const fields: FormField[] = [];

        switch (selectedTaskAddonPaymentAmountType) {
            case TaskAddonPaymentAmountType.TASK_ADDON:
                fields.push(paymentPaymentMethodId);
                break;
            case TaskAddonPaymentAmountType.MANUAL_AMOUNT:
                fields.push(paymentUsdAmount);
                fields.push(paymentPaymentMethodId);
                break;
            case TaskAddonPaymentAmountType.STRIPE_INVOICE:
                fields.push(paymentTaskPublishStripeInvoiceId);
                break;
        }

        return fields;
    };

    const handleAddAddons = async () => {
        if (!task) {
            return;
        }

        if (selectedTaskCategoryAddons.length === 0) {
            return;
        }

        try {
            for (let i = 0; i < selectedTaskCategoryAddons.length; i++) {
                // Create task addon
                await createTaskAddon({
                    task_id: task.id,
                    task_category_addon_id: selectedTaskCategoryAddons[i].id,
                });

                // Close modal
                closeModal?.();

                // TODO: refetch / add api call to insert addon task on Task page
            }
        } catch (e) {
            throw e;
        }
    };

    const handlePayAddons = async () => {
        if (!task) {
            return;
        }

        if (selectedTaskCategoryAddons.length === 0) {
            return;
        }

        const initializeTaskAddonCheckoutInput: InitializeAddonTaskCheckoutInput = {
            task_id: task.id,
            task_category_addon_ids: selectedTaskCategoryAddons.map((addon) => addon.id),
        };

        switch (selectedTaskAddonPaymentAmountType) {
            case TaskAddonPaymentAmountType.MANUAL_AMOUNT: {
                initializeTaskAddonCheckoutInput.is_custom = true;
                initializeTaskAddonCheckoutInput.payment_method_id = paymentPaymentMethodId.value;
                initializeTaskAddonCheckoutInput.amount = Math.trunc(parseFloat(paymentUsdAmount.value) * 100);
                break;
            }
            case TaskAddonPaymentAmountType.STRIPE_INVOICE: {
                initializeTaskAddonCheckoutInput.is_custom = true;
                initializeTaskAddonCheckoutInput.payment_invoice_external_id = paymentTaskPublishStripeInvoiceId.value;
                break;
            }
            case TaskAddonPaymentAmountType.TASK_ADDON: {
                initializeTaskAddonCheckoutInput.payment_method_id = paymentPaymentMethodId.value;
                break;
            }
        }

        try {
            const addonTaskCheckoutRef = await initializeTaskAddonCheckout(initializeTaskAddonCheckoutInput);

            await finalizeTaskAddonCheckout(addonTaskCheckoutRef.id);
        } catch (e) {
            throw e;
        }
    };

    const form = useForm({
        fields: getFormFields(),
        apiCall: async () => {
            if (!task) {
                throw new Error('Could not find task');
            }

            try {
                switch (selectedTaskAddonAssignMethod) {
                    case TaskAddonAssignMethod.PAYMENT:
                        await handlePayAddons();
                        break;
                    case TaskAddonAssignMethod.WITHOUT_PAYMENT:
                        await handleAddAddons();
                        break;
                }

                await fetchTaskAddons({ task_id: task?.id });

                return { response: true };
            } catch (e) {
                throw e;
            }
        },
        onSuccess: () => {
            closeModal?.();
        },
        onFailure: (error) => {
            // TODO: handle error
            console.error(error);
        },
    });

    useEffect(() => {
        paymentUsdAmount.reset();
        paymentPaymentMethodId.reset();
        paymentTaskPublishStripeInvoiceId.reset();
        setSelectedTaskAddonPaymentAmountType(undefined);
    }, [selectedTaskAddonAssignMethod]);

    useEffect(() => {
        if (!task) {
            return;
        }

        const handleInitialize = async () => {
            try {
                if (!clientPaymentAccount) {
                    // Fetch client payment account & payment methods
                    await fetchClientPaymentAccount(task.user.id);
                }
            } catch (e) {
                // TODO: handle error
                console.error(e);
            }
        };

        handleInitialize();
    }, []);

    useEffect(() => {
        if (!data) {
            // Data was NOT passed
            return;
        }

        const handleInitializeTaskCategoryAddons = async () => {
            try {
                // Fetch task category addons
                const taskCategoryAddons = await fetchTaskCategoryAddons({
                    task_category_id: data.taskCategoryId,
                    is_enabled: '1',
                });

                // Initialize task category addons
                taskCategoryAddonsApi.initialize({
                    taskCategoryAddons: taskCategoryAddons.items,
                    excludeIds: data.selectedTaskCategoryAddonIds,
                });
            } catch (e) {
                // TODO: handle error
                console.error(e);
            }
        };

        handleInitializeTaskCategoryAddons();
    }, []);

    useEffect(() => {
        return () => {
            resetTaskCategoryAddons();
        };
    }, []);

    if (!data) {
        closeModal?.();
        return null;
    }

    if (!task) {
        return null;
    }

    const getTotalAddonPrice = () => {
        if (selectedTaskAddonAssignMethod !== TaskAddonAssignMethod.PAYMENT) {
            return 0;
        }

        return selectedTaskCategoryAddons.reduce((accumulator, addon) => {
            if (!addon.price) {
                // Addon do NOT have price
                return accumulator;
            }

            return accumulator + addon.price.unit_amount;
        }, 0);
    };

    const renderAddon = (addon: TaskCategoryAddonResource) => {
        const isAddonSelected = !!selectedTaskCategoryAddons.find((a) => a.id === addon.id);

        const handleToggleTaskCategoryAddonSelection = () => {
            if (isAddonSelected) {
                // Unselect
                setSelectedTaskCategoryAddons((prevValue) => prevValue.filter((a) => a.id !== addon.id));
            } else {
                // Select
                setSelectedTaskCategoryAddons((prevValue) => [addon, ...prevValue]);
            }
        };

        const renderAddonPrice = () => {
            if (!addon.price) {
                return null;
            }

            return (
                <DevText>
                    {PriceUtils.formatPrice({ price: addon.price.unit_amount, shouldDisplayCents: true })}
                </DevText>
            );
        };

        return (
            <Card
                compact
                interactive
                selected={isAddonSelected}
                elevation={Elevation.ONE}
                onClick={handleToggleTaskCategoryAddonSelection}
            >
                <Flex justify="space-between">
                    <div>
                        <DevText>{addon.title}</DevText>
                        <DevText muted>{addon.summary}</DevText>
                    </div>

                    {renderAddonPrice()}
                </Flex>
            </Card>
        );
    };

    const renderAddons = (addons: TaskCategoryAddonResource[]) => {
        return (
            <div>
                <Heading className="mb-1" type="h5">
                    1. Select addons
                </Heading>

                {addons.map((addon, index) => (
                    <div key={addon.id} className={index === 0 ? '' : 'mt-2'}>
                        {renderAddon(addon)}
                    </div>
                ))}
            </div>
        );
    };

    const renderSelectTaskAddonAssignMethod = () => {
        const isCheckoutAllowed = isAddonTaskRootPermission || permissions.has(AddonTaskPermission.CREATE_CHECKOUT);
        const isManualAllowed = isAddonTaskRootPermission || permissions.has(AddonTaskPermission.CREATE_MANUAL);

        if (selectedTaskCategoryAddons.length === 0) {
            // Task category addon(s) are NOT selected
            return null;
        }

        return (
            <div>
                <Heading className="mb-1" type="h5">
                    2. How to add addons
                </Heading>

                <RadioGroup
                    selectedValue={selectedTaskAddonAssignMethod}
                    onChange={(e) => setSelectedTaskAddonAssignMethod(e.currentTarget.value as TaskAddonAssignMethod)}
                    className="control-card-group-row"
                >
                    {isCheckoutAllowed && (
                        <RadioCard
                            compact
                            elevation={Elevation.ONE}
                            label="Add with payment"
                            value={TaskAddonAssignMethod.PAYMENT}
                            alignIndicator={Alignment.RIGHT}
                        />
                    )}

                    {isManualAllowed && (
                        <RadioCard
                            compact
                            elevation={Elevation.ONE}
                            label="Add without payment"
                            value={TaskAddonAssignMethod.WITHOUT_PAYMENT}
                            alignIndicator={Alignment.RIGHT}
                        />
                    )}
                </RadioGroup>
            </div>
        );
    };

    const renderPaymentDetails = () => {
        const isCustomAllowed = isAddonTaskRootPermission || permissions.has(AddonTaskPermission.CREATE_CHECKOUT_CUSTOM);

        if (selectedTaskCategoryAddons.length === 0) {
            // Task category addon(s) are NOT selected
            return null;
        }

        if (selectedTaskAddonAssignMethod !== TaskAddonAssignMethod.PAYMENT) {
            // Only show payment details whenever addon assign method is payment
            return null;
        }

        const renderTaskCategoryRadio = () => {
            const radioLabel = 'Default task category addons price';

            return (
                <Radio
                    checked={selectedTaskAddonPaymentAmountType === TaskAddonPaymentAmountType.TASK_ADDON}
                    className="mb-1"
                    label={radioLabel}
                    onChange={() => setSelectedTaskAddonPaymentAmountType(TaskAddonPaymentAmountType.TASK_ADDON)}
                />
            );
        };

        const renderManualUSDAmountRadio = () => {
            if (!isCustomAllowed) {
                return null;
            }

            return (
                <Radio
                    className="mb-1"
                    checked={selectedTaskAddonPaymentAmountType === TaskAddonPaymentAmountType.MANUAL_AMOUNT}
                    label="Manual amount"
                    onChange={() => setSelectedTaskAddonPaymentAmountType(TaskAddonPaymentAmountType.MANUAL_AMOUNT)}
                />
            );
        };

        const renderManualUSDAmountInput = () => {
            if (selectedTaskAddonPaymentAmountType !== TaskAddonPaymentAmountType.MANUAL_AMOUNT) {
                // Show manual USD amount input only whenever <code>amountType === AmountType.MANUAL</code>
                return null;
            }

            const defaultTotalAddonPrice = getTotalAddonPrice();

            return (
                <InputFormField
                    field={paymentUsdAmount}
                    formGroupProps={{
                        label: 'Amount (USD)',
                        helperText:
                            paymentUsdAmount.error ??
                            `Default addon price: ${PriceUtils.formatPrice({
                                price: defaultTotalAddonPrice,
                                shouldDisplayCents: true,
                            })}`,
                    }}
                    inputProps={{ placeholder: 'Enter amount of USD' }}
                />
            );
        };

        const renderStripeIdRadio = () => {
            if (!isCustomAllowed) {
                return null;
            }

            return (
                <Radio
                    className="mb-1"
                    checked={selectedTaskAddonPaymentAmountType === TaskAddonPaymentAmountType.STRIPE_INVOICE}
                    label="Use already paid Stripe invoice"
                    onChange={() => setSelectedTaskAddonPaymentAmountType(TaskAddonPaymentAmountType.STRIPE_INVOICE)}
                />
            );
        };

        const renderStripeIdInput = () => {
            // Show manual USD amount input only whenever <code>amountType === AmountType.MANUAL</code>
            if (selectedTaskAddonPaymentAmountType !== TaskAddonPaymentAmountType.STRIPE_INVOICE) {
                return null;
            }

            return (
                <InputFormField
                    field={paymentTaskPublishStripeInvoiceId}
                    formGroupProps={{ label: 'Stripe invoice ID' }}
                    inputProps={{ placeholder: 'Enter stripe invoice ID' }}
                />
            );
        };

        const renderPaymentMethodSelect = () => {
            // Do not allow choosing payment method if <code>amountType === STRIPE_INVOICE</code>
            if (selectedTaskAddonPaymentAmountType === TaskAddonPaymentAmountType.STRIPE_INVOICE) {
                return null;
            }

            let selectButtonText: string | null = null;
            if (paymentPaymentMethodId.value && clientPaymentAccount) {
                const foundClientPaymentMethod = clientPaymentAccount.payment_methods.find(
                    (p) => p.id === paymentPaymentMethodId.value,
                );
                if (foundClientPaymentMethod) {
                    selectButtonText = `${foundClientPaymentMethod.card_brand} **** ${foundClientPaymentMethod.card_last_four}`;
                }
            }

            const renderItem = (item: PaymentMethodResource, { handleClick }: ItemRendererProps) => {
                const isMenuItemActive = item.id === paymentPaymentMethodId.value;

                return (
                    <MenuItem
                        active={isMenuItemActive}
                        key={item.id}
                        text={`${item.card_brand} **** ${item.card_last_four}`}
                        onClick={handleClick}
                    />
                );
            };

            const handleItemSelect = (item: PaymentMethodResource) => {
                paymentPaymentMethodId.handleChange(item.id);
            };

            return (
                <FormGroup
                    label="Payment method"
                    intent={!!paymentPaymentMethodId.error ? Intent.DANGER : Intent.NONE}
                    helperText={paymentPaymentMethodId.error}
                >
                    <Select<PaymentMethodResource>
                        filterable
                        disabled={!clientPaymentAccount || clientPaymentAccount.payment_methods.length === 0}
                        items={clientPaymentAccount ? clientPaymentAccount.payment_methods : []}
                        itemRenderer={renderItem}
                        onItemSelect={handleItemSelect}
                        popoverProps={{
                            matchTargetWidth: true,
                            usePortal: false,
                        }}
                        selectButtonProps={{
                            fill: true,
                            rightIcon: 'double-caret-vertical',
                            placeholder: 'Select payment method',
                            text: selectButtonText,
                        }}
                    />
                </FormGroup>
            );
        };

        return (
            <div>
                <Heading type="h5" className="mb-1">
                    3. Payment details
                </Heading>

                {/* Task category credit price */}
                {renderTaskCategoryRadio()}

                {/* Manual amount of USD */}
                {renderManualUSDAmountRadio()}
                {renderManualUSDAmountInput()}

                {/* Stripe invoice ID*/}
                {renderStripeIdRadio()}
                {renderStripeIdInput()}

                {/* Payment method select */}
                <div className="mt-2">{renderPaymentMethodSelect()}</div>
            </div>
        );
    };

    const renderTotalLabel = () => {
        if (selectedTaskCategoryAddons.length === 0) {
            // Task category addon(s) are NOT selected
            return null;
        }

        if (selectedTaskAddonAssignMethod !== TaskAddonAssignMethod.PAYMENT) {
            // Method is NOT payment
            return null;
        }

        if (selectedTaskAddonPaymentAmountType === TaskAddonPaymentAmountType.STRIPE_INVOICE) {
            // User already payed, no need to show total
            return null;
        }

        // Get addon total price
        const totalAddonPrice = getTotalAddonPrice();

        if (
            selectedTaskAddonPaymentAmountType === TaskAddonPaymentAmountType.MANUAL_AMOUNT &&
            paymentUsdAmount.value &&
            !paymentUsdAmount.error
        ) {
            return (
                <DevText>
                    Total:{' '}
                    {PriceUtils.formatPrice({
                        price: parseFloat(paymentUsdAmount.value),
                        shouldDisplayCents: true,
                        isPriceInCents: false,
                    })}
                </DevText>
            );
        } else {
            return (
                <DevText>Total: {PriceUtils.formatPrice({ price: totalAddonPrice, shouldDisplayCents: true })}</DevText>
            );
        }
    };

    const renderControls = () => {
        switch (selectedTaskAddonAssignMethod) {
            case TaskAddonAssignMethod.PAYMENT: {
                const isPayAddonsButtonDisabled =
                    form.hasFieldErrors || selectedTaskCategoryAddons.length === 0 || !selectedTaskAddonAssignMethod;

                return (
                    <Flex direction="row" justify="space-between" align="center">
                        {renderTotalLabel()}

                        <Flex className="ml-auto" direction="row" justify="center">
                            <Button className="mr-1" onClick={closeModal}>
                                Cancel
                            </Button>

                            <Button
                                disabled={isPayAddonsButtonDisabled}
                                loading={form.isSubmitting}
                                intent={Intent.PRIMARY}
                                type="submit"
                            >
                                Pay
                            </Button>
                        </Flex>
                    </Flex>
                );
            }
            case TaskAddonAssignMethod.WITHOUT_PAYMENT: {
                const isAddAddonsButtonDisabled = selectedTaskCategoryAddons.length === 0;

                return (
                    <Flex direction="row" justify="flex-end">
                        <Button
                            disabled={isAddAddonsButtonDisabled}
                            loading={form.isSubmitting}
                            type="submit"
                            intent={Intent.PRIMARY}
                        >
                            Add addons
                        </Button>
                    </Flex>
                );
            }
        }
    };

    const renderContent = () => {
        if (!taskCategoryAddons || !clientPaymentAccount) {
            // Task category addons are still fetching
            return (
                <Flex justify="center">
                    <Spinner />
                </Flex>
            );
        }

        if (taskCategoryAddons.length === 0) {
            // There are no addons for this task category
            return (
                <div>
                    <NonIdealState
                        className="mb-2"
                        layout="horizontal"
                        icon={<Icon className="mr-2" icon="search" size={40} />}
                        title={
                            <DevText running>
                                You can't assign any addons, because request category - "{task.category.title}" do not
                                have any addons associated with it
                            </DevText>
                        }
                    />

                    <Flex justify="flex-end">
                        <Button intent={Intent.PRIMARY} onClick={closeModal}>
                            Close modal
                        </Button>
                    </Flex>
                </div>
            );
        }

        return (
            <div>
                <div className="mb-3">{renderAddons(taskCategoryAddons)}</div>
                <div className="mb-3">{renderSelectTaskAddonAssignMethod()}</div>
                <div>{renderPaymentDetails()}</div>
                <div className="mt-2">{renderControls()}</div>
            </div>
        );
    };

    return (
        <Overlay isOpen onClose={closeModal}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="start" justify="space-between">
                    <Heading type="h4" className="mb-1">
                        Assign addons to request
                    </Heading>

                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <form onSubmit={form.handleFormSubmit}>{renderContent()}</form>
            </Card>
        </Overlay>
    );
};

export default AssignTaskAddonModal;
