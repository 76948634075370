import {
    checkValidators,
    getStringNumericValueMinValidator,
    getStringNumericValueOfTypeIntValidator,
    getStringNumericValueValidator,
} from '@app/hooks/validation/functions';
import { Button, Card, Divider, FormGroup, Intent, Spinner } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Overlay from '@components/Overlay';
import AddSubscriptionPriceControl from '@containers/components/AddSubscriptionPriceControl';
import SelectedSubscriptionPrice from '@containers/components/SelectedSubscriptionPrice';
import SubscriptionUpdateAnchorSelector from '@containers/components/SubscriptionUpdateAnchorSelector';
import { subscriptionModeAllowedPlanTypesInformation } from '@data/consts';
import { checkIfPricesHaveTheSameRecurringInterval } from '@data/functions/subscription';
import { ModalProps } from '@modals/types';
import { SubscriptionMode, SubscriptionUpdateAnchor } from 'dy-frontend-http-repository/lib/modules/Subscription/enums';
import { PlanResource, PriceResource } from 'dy-frontend-http-repository/lib/modules/SubscriptionPlan/resources';
import { useStore } from 'effector-react';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { fetchSubscriptionPlans, updateSubscriptionSingleItem } from './store/effects';
import { resetSubscriptionPlans } from './store/events';
import { $subscriptionPlans } from './store/states';
import Heading from '@components/Heading';
import { $permissions } from '@containers/store/states';
import { SubscriptionPermission } from 'dy-frontend-permissions/lib/permission';

const selectedSubscriptionPriceQuantityValidators = [
    getStringNumericValueValidator(),
    getStringNumericValueOfTypeIntValidator(),
    getStringNumericValueMinValidator({ minValue: 0 }),
];

export interface UpdateSubscriptionSingleItemModalProps {
    price: PriceResource;
    subscriptionId: ID;
    subscriptionMode: SubscriptionMode;
    subscriptionItemQuantity: number;
    selectedPriceIds: ID[];
    refetchOnSuccess?: () => Promise<unknown>;
}

type Props = ModalProps<UpdateSubscriptionSingleItemModalProps>;

const UpdateSubscriptionSingleItemModal: React.FC<Props> = ({ closeModal, data }) => {
    const permissions = useStore($permissions);
    const isSubscriptionRootPermission = permissions.has(SubscriptionPermission.ROOT);

    const subscriptionPlans = useStore($subscriptionPlans);

    const [isUpdatingSingleItem, setIsUpdatingSingleItem] = useState(false);

    const [newSubscriptionPrice, setNewSubscriptionPrice] = useState<PriceResource | null>(null);
    const [newSubscriptionPriceError, setNewSubscriptionPriceError] = useState<string | null>(null);

    const [newSubscriptionPriceQuantity, setNewSubscriptionPriceQuantity] = useState<string>('');
    const [newSubscriptionPriceQuantityError, setNewSubscriptionPriceQuantityError] = useState<string | null>(null);

    const [updateAnchor, setUpdateAnchor] = useState<SubscriptionUpdateAnchor | null>(null);
    const [updateAnchorError, setUpdateAnchorError] = useState<string | null>(null);
    const [updateAnchorAt, setUpdateAnchorAt] = useState<Date | null>(null);
    const [updateAnchorAtError, setUpdateAnchorAtError] = useState<string | null>(null);

    const hasFieldErrors =
        newSubscriptionPriceError !== null ||
        newSubscriptionPriceQuantityError !== null ||
        updateAnchorError !== null ||
        updateAnchorAtError !== null;

    const priceIdToPlanResourceMap = useMemo<{ [priceId in ID]: PlanResource }>(() => {
        if (!subscriptionPlans) {
            return {};
        }

        const map: { [priceId in ID]: PlanResource } = {};
        subscriptionPlans.items.forEach((plan) =>
            plan.prices.forEach((price) => {
                map[price.id] = plan;
            })
        );
        return map;
    }, [subscriptionPlans]);

    useEffect(() => {
        // Fetch subscription plans to get prices
        if (!data) {
            return;
        }

        // Get plan type by subscription mode
        const allowedPlanTypes = subscriptionModeAllowedPlanTypesInformation[data.subscriptionMode];
        if (allowedPlanTypes.length === 0) {
            // There are 0 allowed plans for this subscription mode;
            closeModal?.();
            return;
        }

        fetchSubscriptionPlans(allowedPlanTypes[0]).catch((e) => {
            // TODO: handle error
            console.error(e);
        });
    }, []);

    useEffect(() => {
        // Validate new price quantity
        const validateNewPriceQuantity = async () => {
            // Validate price quantity
            const validationResult = await checkValidators<string>(
                `${newSubscriptionPriceQuantity}`,
                selectedSubscriptionPriceQuantityValidators
            );

            setNewSubscriptionPriceQuantityError(validationResult);
        };

        validateNewPriceQuantity();
    }, [newSubscriptionPriceQuantity]);

    useEffect(() => {
        // Validate new subscription price
        if (newSubscriptionPrice === null) {
            setNewSubscriptionPriceError('You need to choose new price');
        } else {
            setNewSubscriptionPriceError(null);
        }
    }, [newSubscriptionPrice]);

    useEffect(() => {
        return () => {
            resetSubscriptionPlans();
        };
    }, []);

    if (!data) {
        closeModal?.();
        return null;
    }

    const handleUpdateSubscription = async () => {
        try {
            if (hasFieldErrors) {
                throw new Error('Not all fields are valid');
            }

            setIsUpdatingSingleItem(true);

            // Get numeric price quantity
            const newPriceQuantityNumeric = parseInt(newSubscriptionPriceQuantity);
            if (isNaN(newPriceQuantityNumeric) || newPriceQuantityNumeric < 1) {
                // Price quantity it not a numeric value OR less then 1
                throw new Error('Price quantity is not valid');
            }

            const subscriptionRef = await updateSubscriptionSingleItem({
                oldPriceId: data.price.id,
                subscriptionId: data.subscriptionId,
                input: {
                    next_price_id: newSubscriptionPrice!.id,
                    update_anchor: updateAnchor!,
                    update_anchor_at: updateAnchorAt ? moment(updateAnchorAt).utc().format() : null,
                    quantity: newPriceQuantityNumeric,
                },
            });

            // Refetch if refetch function provided
            if (data.refetchOnSuccess) {
                const refetch = () =>
                    new Promise((resolve, reject) => {
                        setTimeout(
                            () =>
                                data.refetchOnSuccess &&
                                data
                                    .refetchOnSuccess()
                                    .then((r) => resolve(r))
                                    .catch((e) => reject(e)),
                            5000
                        );
                    });
                const refetchResult = await refetch();
            }

            closeModal?.();
        } catch (e) {
            // TODO: handle error
            console.error(e);
        } finally {
            setIsUpdatingSingleItem(false);
        }
    };

    const renderOldSubscriptionPrice = () => {
        if (!data) {
            return null;
        }

        const renderOldSubscriptionPrice = (price: PriceResource, isLastElement: boolean) => {
            const plan = priceIdToPlanResourceMap[price.id];

            return (
                <SelectedSubscriptionPrice
                    className={!isLastElement ? 'mb-2' : ''}
                    key={price.id}
                    quantity={`${data.subscriptionItemQuantity}`}
                    price={price}
                    plan={plan}
                />
            );
        };

        return (
            <div>
                <FormGroup label="Current item to replace">
                    {renderOldSubscriptionPrice(
                        { ...data.price, is_legacy: false, external_id: 'unknown', plan_visibility: 'unknown' },
                        false
                    )}
                </FormGroup>
            </div>
        );
    };

    const renderNewSubscriptionPrice = () => {
        const renderNewSubscriptionPrice = (price: PriceResource, isLastElement: boolean) => {
            const plan = priceIdToPlanResourceMap[price.id];

            const handleRemoveSubscriptionPrice = () => {
                setNewSubscriptionPriceQuantity('0');
                setNewSubscriptionPrice(null);
            };

            const handleQuantityChange = (newQuantity: string) => {
                setNewSubscriptionPriceQuantity(newQuantity);
            };

            return (
                <SelectedSubscriptionPrice
                    className={!isLastElement ? 'mb-2' : ''}
                    key={price.id}
                    quantity={newSubscriptionPriceQuantity}
                    price={price}
                    plan={plan}
                    onRemoveQuantity={handleRemoveSubscriptionPrice}
                    onChangeQuantity={handleQuantityChange}
                />
            );
        };

        return (
            <div>
                <FormGroup
                    label="Next item"
                    intent={
                        !!newSubscriptionPriceQuantityError || !!newSubscriptionPriceError ? Intent.DANGER : Intent.NONE
                    }
                    helperText={newSubscriptionPriceError ?? newSubscriptionPriceQuantityError}
                >
                    {newSubscriptionPrice && renderNewSubscriptionPrice(newSubscriptionPrice, false)}
                </FormGroup>
            </div>
        );
    };

    const renderAddSubscriptionPriceButton = () => {
        if (!subscriptionPlans) {
            return null;
        }

        const handleSelectPrice = (price: PriceResource) => {
            setNewSubscriptionPrice(price);
            setNewSubscriptionPriceQuantity('1');
        };

        return (
            <AddSubscriptionPriceControl
                selectedPrices={newSubscriptionPrice ? [newSubscriptionPrice] : []}
                disabledPriceIds={data.selectedPriceIds}
                plans={subscriptionPlans.items}
                onSelectPrice={handleSelectPrice}
                shouldPriceBeActive={(price) => checkIfPricesHaveTheSameRecurringInterval([price, data.price])}
            />
        );
    };

    const renderContent = () => {
        if (!subscriptionPlans) {
            // Either subscription plans were NOT initialized yet
            return (
                <Flex align="center" justify="center">
                    <Spinner />
                </Flex>
            );
        }

        return (
            <Card style={{ width: '600px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Switch subscription item</Heading>
                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                {renderOldSubscriptionPrice()}

                <Divider className="mb-2" />

                {renderNewSubscriptionPrice()}
                {renderAddSubscriptionPriceButton()}

                <Divider className="mt-2 mb-2" />

                <SubscriptionUpdateAnchorSelector
                    updateAnchor={updateAnchor}
                    updateAnchorError={updateAnchorError}
                    updateAnchorAt={updateAnchorAt}
                    updateAnchorAtError={updateAnchorAtError}
                    onChangeUpdateAnchor={setUpdateAnchor}
                    onChangeUpdateAnchorAt={setUpdateAnchorAt}
                    onChangeUpdateAnchorError={setUpdateAnchorError}
                    onChangeUpdateAnchorAtError={setUpdateAnchorAtError}
                />

                <Flex className="mt-2" justify="flex-end">
                    <Button className="mr-1" outlined onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button loading={isUpdatingSingleItem} disabled={hasFieldErrors} onClick={handleUpdateSubscription}>
                        Update subscription
                    </Button>
                </Flex>
            </Card>
        );
    };

    return (
        <Overlay isOpen onClose={closeModal}>
            {renderContent()}
        </Overlay>
    );
};

export default UpdateSubscriptionSingleItemModal;
