import Flex from '@app/components/Flex';
import RouterLink from '@app/components/RouterLink';
import DevText from '@app/components/Text';
import { Endpoints } from '@app/data/consts';
import { Colors, Icon } from '@blueprintjs/core';
import React, { HTMLAttributes } from 'react';

export interface PlanTagProps {
    id: ID;
    name: string;
    quantity: number;
    color?: string | null;
}

export type Props = HTMLAttributes<HTMLDivElement> & PlanTagProps;

const PlanTag: React.FC<Props> = ({ id, name, color = undefined, quantity, ...props }) => {
    const renderQuantity = () => {
        if (quantity <= 1) {
            return null;
        }

        return (
            <DevText muted className="ml-small">
                x{quantity}
            </DevText>
        );
    };
    return (
        <Flex direction="row" align="center" {...props}>
            <Icon className="mr-1" icon="box" color={color} />
            <RouterLink color={Colors.WHITE} to={Endpoints.PLAN.replace(':planId', id)}>
                <DevText>{name}</DevText>
            </RouterLink>
            {renderQuantity()}
        </Flex>
    );
};

export default PlanTag;
