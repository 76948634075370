import React, { useEffect, useState } from 'react';
import { ModalProps } from '@modals/types';
import { PlanQuotaAttachmentResource } from 'dy-frontend-http-repository/lib/modules/Plan/resources';
import Overlay from '@components/Overlay';
import {
    Button,
    Card,
    Checkbox,
    Divider,
    Elevation,
    FormGroup,
    HTMLSelect,
    InputGroup,
    Intent,
    Tooltip,
} from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import { PlanQuotaAttachmentInput } from 'dy-frontend-http-repository/lib/modules/Plan/inputs';
import { updatePlanQuotaAttachmentList } from '@pages/Plan/store/effects';
import { planApi } from '@pages/Plan/store/apis';
import { QuotaMergeMode, QuotaType } from 'dy-frontend-http-repository/lib/data/enums';
import { quotaTypeInformation } from '@data/consts';

export interface SetPlanQuotaListModalProps {
    planId: ID;
    initialAttachmentList: PlanQuotaAttachmentResource[];
}

type Props = ModalProps<SetPlanQuotaListModalProps>;

const SetPlanQuotaListModal: React.FC<Props> = ({ closeModal, data }) => {
    const [isProcessing, setIsProcessing] = useState(false);
    const [attachments, setAttachments] = useState<PlanQuotaAttachmentResource[] | null>(null);
    const [quotaPickerValue, setQuotaPickerValue] = useState<QuotaType | null>(null);

    useEffect(() => {
        if (!data) {
            handleModalClose();
            return;
        }

        // Skip if already initialized
        if (attachments !== null) {
            return;
        }

        setAttachments(data.initialAttachmentList);
    }, [data]);

    const handleModalClose = () => {
        if (isProcessing) {
            return;
        }

        closeModal?.();
    };

    if (!data) {
        handleModalClose();
        return null;
    }

    const handleSubmit = () => {
        if (attachments === null) {
            return;
        }

        // Create output data
        const nextAttachmentList: PlanQuotaAttachmentInput[] = attachments.map((i) => ({
            type: i.type,
            merge_mode: i.merge_mode,
            value: i.value,
            is_quantity_multiply_allowed: i.is_quantity_multiply_allowed,
        }));

        // Send data
        setIsProcessing(true);
        updatePlanQuotaAttachmentList({ planId: data.planId, input: nextAttachmentList })
            .then(() => {
                // Touch API
                planApi.updateQuotaList(attachments);

                // Close modal
                closeModal?.();
            })
            .catch(console.error) // TODO: Handle error
            .finally(() => setIsProcessing(false));

        return;
    };

    const removeQuotaAttachment = (index: number) => {
        if (attachments === null) {
            return;
        }
        setAttachments(attachments.filter((_, i) => i !== index));
    };

    const setQuotaAttachmentValue = (index: number, value: number) => {
        if (attachments === null) {
            return;
        }

        // Don't allow less than 1
        const normalized = value < 1 ? 1 : value;

        setAttachments(
            attachments.map((a, i) => {
                if (i !== index) {
                    return a;
                }

                return { ...a, value: normalized };
            })
        );
    };

    const setQuotaAttachmentMultiplyMode = (index: number, isAllowed: boolean) => {
        if (attachments === null) {
            return;
        }

        setAttachments(
            attachments.map((a, i) => {
                if (i !== index) {
                    return a;
                }

                return { ...a, is_quantity_multiply_allowed: isAllowed };
            })
        );
    };

    const setQuotaAttachmentMergeMode = (index: number, mergeMode: QuotaMergeMode) => {
        if (attachments === null) {
            return;
        }

        setAttachments(
            attachments.map((a, i) => {
                if (i !== index) {
                    return a;
                }

                return { ...a, merge_mode: mergeMode };
            })
        );
    };

    const renderAttachmentItem = (index: number, item: PlanQuotaAttachmentResource) => {
        const readableQuotaType = quotaTypeInformation[item.type] ?? 'Unknown';

        return (
            <Card compact elevation={Elevation.TWO}>
                <Flex justify="space-between">
                    <div>
                        <Flex className="mb-1" align="center">
                            <HTMLSelect
                                className="mr-1"
                                value={item.merge_mode}
                                onChange={(e) => setQuotaAttachmentMergeMode(index, e.target.value as QuotaMergeMode)}
                            >
                                <option value={QuotaMergeMode.SET}>Set</option>
                                <option value={QuotaMergeMode.SUM}>Add</option>
                            </HTMLSelect>
                            <div style={{ width: '100px' }} className="mr-1">
                                <InputGroup
                                    placeholder="Amount"
                                    value={String(item.value)}
                                    onChange={(e) => setQuotaAttachmentValue(index, parseInt(e.target.value))}
                                />
                            </div>
                            <DevText>{readableQuotaType}</DevText>
                        </Flex>
                        <Checkbox
                            label="Scale amount by licence quantity"
                            onChange={(e) => setQuotaAttachmentMultiplyMode(index, e.target.checked)}
                            checked={item.is_quantity_multiply_allowed}
                        />
                    </div>
                    <div>
                        <Tooltip content="Remove quota rule">
                            <Button icon="cross" onClick={() => removeQuotaAttachment(index)} />
                        </Tooltip>
                    </div>
                </Flex>
            </Card>
        );
    };

    const renderAttachmentList = () => {
        if (attachments === null) {
            return null;
        }

        if (attachments.length === 0) {
            return <DevText muted>No quotas selected</DevText>;
        }

        return attachments.map((i, index) => (
            <div key={`quota-${i.type}`} className="mb-1">
                {renderAttachmentItem(index, i)}
            </div>
        ));
    };

    const handleAddQuotaType = (type: QuotaType) => {
        if (!attachments) {
            return;
        }

        setAttachments([
            ...attachments,
            {
                type: type,
                merge_mode: QuotaMergeMode.SUM,
                value: 1,
                is_quantity_multiply_allowed: false,
            },
        ]);
    };

    const renderQuotaTypePicker = () => {
        if (!attachments) {
            return null;
        }

        const selectedQuotaTypeList = attachments.map((x) => x.type);
        const availableQuotaTypes = Object.keys(quotaTypeInformation).filter((x) => !selectedQuotaTypeList.includes(x));

        return (
            <FormGroup label="Select quota type to add">
                <Flex>
                    <HTMLSelect
                        fill
                        className="mr-1"
                        onChange={(e) => setQuotaPickerValue(e.target.value as QuotaType)}
                        value={String(quotaPickerValue)}
                        disabled={availableQuotaTypes.length === 0}
                    >
                        <option value={undefined}>Select quota</option>
                        {availableQuotaTypes.map((i) => (
                            <option key={`qt-${i}`} value={i}>
                                {quotaTypeInformation[i] ?? 'Unknown'}
                            </option>
                        ))}
                    </HTMLSelect>
                    <Button
                        disabled={quotaPickerValue === null}
                        onClick={() => {
                            if (quotaPickerValue === null) {
                                return;
                            }
                            handleAddQuotaType(quotaPickerValue);
                            setQuotaPickerValue(null);
                        }}
                    >
                        Add
                    </Button>
                </Flex>
            </FormGroup>
        );
    };

    return (
        <Overlay isOpen onClose={handleModalClose}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Plan quota / limitations list</Heading>
                    <Button minimal icon="cross" onClick={closeModal} disabled={isProcessing} />
                </Flex>

                <Divider className="mb-2" />

                <div className="mb-2">{renderQuotaTypePicker()}</div>

                <Divider className="mb-2" />

                <div className="mb-2">{renderAttachmentList()}</div>

                <Divider className="mb-2" />

                <Flex align="center" justify="space-between">
                    <DevText muted>Save quota info?</DevText>
                    <div>
                        <Button minimal onClick={handleModalClose} className="mr-1" disabled={isProcessing}>
                            No, cancel
                        </Button>
                        <Button intent={Intent.PRIMARY} loading={isProcessing} onClick={handleSubmit}>
                            Yes, save data
                        </Button>
                    </div>
                </Flex>
            </Card>
        </Overlay>
    );
};

export default SetPlanQuotaListModal;
