import React, { useEffect } from 'react';
import { useStore } from 'effector-react';
import { $client, $clientPaymentAccount } from '@pages/Client/store/states';
import { resetInvoices, resetSubscriptions } from './store/events';
import Flex from '@components/Flex';
import PaymentMethods from './components/PaymentMethods';
import Subscriptions from './components/Subscriptions';
import Invoices from './components/Invoices';
import { Spinner } from '@blueprintjs/core';
import { usePageTitle } from '@app/hooks';
import TaxIds from './components/TaxIds';
import { $permissions } from '@containers/store/states';
import { PaymentMethodPermission, SubscriptionPermission, TaxIdPermission } from 'dy-frontend-permissions/lib/permission';

const ClientBilling: React.FC = () => {
    const permissions = useStore($permissions);

    const { changeTitle } = usePageTitle('Client Billing');

    const client = useStore($client);
    const clientPaymentAccount = useStore($clientPaymentAccount);

    useEffect(() => {
        if (!client) {
            return;
        }

        changeTitle(`Client Billing - ${client.first_name} ${client.last_name}`);
    }, [client?.first_name, client?.last_name]);

    useEffect(() => {
        return () => {
            resetSubscriptions();
            resetInvoices();
        };
    }, []);

    // Client was not fetched yet
    if (!client) {
        return null;
    }

    if (!permissions.isEnabled.paymentAccount) {
        return null;
    }

    // Client payment account was NOT fetched yet
    if (!clientPaymentAccount) {
        return (
            <Flex justify="center">
                <Spinner />
            </Flex>
        );
    }

    const isInvoiceAuthModuleEnabled = true; // TODO: Implement

    console.log(permissions)

    return (
        <div>
            {permissions.isEnabled.subscription && (<Subscriptions className="mb-4" />)}
            {permissions.isEnabled.paymentMethod && (<PaymentMethods className="mb-4" />)}
            {permissions.isEnabled.taxId && (<TaxIds className="mb-4" />)}
            {isInvoiceAuthModuleEnabled && (<Invoices />)}
        </div>
    );
};

export default ClientBilling;
