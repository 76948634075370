import React, { HTMLAttributes } from 'react';
import moment from 'moment';
import { TaskMessageListItemResource } from 'dy-frontend-http-repository/lib/modules/TaskMessage/resources';
import RichText from '@components/RichText';
import { Card } from '@blueprintjs/core';
import Heading from '@components/Heading';
import DevText from '@components/Text';

export interface BotMessageProps {
    taskMessage: TaskMessageListItemResource;
}

export type Props = BotMessageProps & HTMLAttributes<HTMLDivElement>;

const BotMessage: React.FC<Props> = ({ taskMessage, ...props }) => {
    /*
     * TODO: think about what actions can be performed on BOT message
     * - remove
     */

    return (
        <Card {...props}>
            <div className="mb-1">
                <Heading className="mr-1" type="h5">
                    Latest updated
                </Heading>

                <DevText muted>{moment(taskMessage.updated_at).format('DD.MM.YYYY HH:mm')}</DevText>
            </div>

            <RichText value={taskMessage.content} />
        </Card>
    );
};

export default BotMessage;
