import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { TaskDeliverableResource } from 'dy-frontend-http-repository/lib/modules/TaskDeliverable/resources';
import { TaskDeliverableCommentResource } from 'dy-frontend-http-repository/lib/modules/TaskDeliverableComment/resources';
import { createStore } from 'effector';
import { PointMarkerByTaskDeliverableCommentIdMap, RootTaskDeliverableCommentsByIdMap } from '../types';
import domain from './domain';
import {
    resetHighlightedTaskDeliverableCommentId,
    resetPointMarker,
    resetPointMarkerByTaskDeliverableCommentIdMap,
    resetQueuedVideoTimelinePositionMS,
    resetRootTaskDeliverableCommentsByIdMap,
    resetSelectedTaskDeliverable,
    resetTaskDeliverableComments,
    resetTimeMarker,
} from './events';

// Selected task deliverable
export const $selectedTaskDeliverable = domain
    .createStore<TaskDeliverableResource | null>(null)
    .reset(resetSelectedTaskDeliverable);

// Task deliverable comments
export const $taskDeliverableComments = domain
    .createStore<CollectionResource<TaskDeliverableCommentResource, {}> | null>(null)
    .reset(resetTaskDeliverableComments);

// Root task deliverable comments by ID
export const $rootTaskDeliverableCommentsByIdMap = domain
    .createStore<RootTaskDeliverableCommentsByIdMap | null>(null)
    .reset(resetRootTaskDeliverableCommentsByIdMap);

// Time marker
export const $timeMarker = createStore<TaskDeliverableCommentResource['marker_time'] | null>(null).reset(
    resetTimeMarker
);

// Point marker
export const $pointMarker = createStore<TaskDeliverableCommentResource['marker_point'] | null>(null).reset(
    resetPointMarker
);

// Point markers by task deliverable IDs
export const $pointMarkerByTaskDeliverableCommentIdMap = createStore<PointMarkerByTaskDeliverableCommentIdMap>(
    {}
).reset(resetPointMarkerByTaskDeliverableCommentIdMap);

// Highlighted task deliverable comment id
export const $highlightedTaskDeliverableCommentId = createStore<ID | null>(null).reset(
    resetHighlightedTaskDeliverableCommentId
);

// Queued video timeline position in milliseconds
export const $queuedVideoTimelinePositionMS = createStore<number | null>(null).reset(
    resetQueuedVideoTimelinePositionMS
);
